$(function() {
    $(document).on('click', '.input-clear', function() {
        $(this).prev('input').val('').trigger('change');
    })
    
});

$(document).on('change input load', '.has-input-clear > input', function() {
    var showClearButton = $(this).val().trim() != '';
    
    $(this).next('.input-clear').toggleClass('visible', showClearButton);
});
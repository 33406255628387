$(function() {
    (function(history){
        var pushState = history.pushState;
        var changeViewContainer = $('#change-view-entries')
        
        history.pushState = function(state) {
            if (typeof history.onpushstate == "function") {
                history.onpushstate({state: state});
            }
            
            var currentUri = state.path.replace(contextPath, '');
            
            changeViewContainer.find('a').each(function(index, a) {
                var uri = URI($(a).attr('href')).removeSearch('currentRequestedPage').addSearch('currentRequestedPage', currentUri);
                $(a).attr('href', uri);
            });
            
            return pushState.apply(history, arguments);
        }
    })(window.history);
});
$(function() {
    var isTab = function(path) {
        return window.location.pathname.startsWith(contextPath + path);
    }
    
    function rememberVisitedPageOfTab() {
        var firstLoad = true;
        page("/", function(_ctx, next) {
            if(!firstLoad) {
                return next();
            }
            
            firstLoad = false;
            
            var href = pageOfTabRememberer.getLastPage();
            
            if(href && href != '/') {
                page(href);
            }
            
            next();
        });
        
        page(function(ctx, next) {
            ctx.firstLoad = firstLoad;
            next();
            firstLoad = false;
        })
        
        page(function(ctx, next) {
            pageOfTabRememberer.setLastPage(ctx.path);

            next();
        });
    }
    
    function forgetRememberdVisitedPageOfTab(_ctx, next) {
        pageOfTabRememberer.removeLastPage();
        
        next();
    }

    var loadProcessRouting = function() {
        var processPage = new ProcessPage();

        page.base(contextPath + '/process');
        
        page.exit(processPage.pageChangeFromJs);
        
        page('/:culture([A-Za-z]{1,8}-[A-Za-z0-9]{1,8})?/:processId([0-9]+)/:elementId([0-9]+)?/*', processPage.prepare);
        
        page('/'
                , processPage.prepare
                , processPage.openStartPage);
        
        page('/:culture/:processId([0-9]+)'
                , processPage.prepare
                , processPage.loadProcessData
                , processPage.showProcessGraphic
                , processPage.focusShape);
        
        page('/:culture/:processId([0-9]+)/:elementId([0-9]+):showStepDetails(/details)?'
                , processPage.prepare
                , processPage.loadProcessData
                , processPage.loadProcessStepDetails
                , processPage.showProcessGraphic
                , processPage.focusShape);
        
        page('/:culture/:processId([0-9]+)/details'
                , processPage.loadProcessData
                , processPage.showProcessDetails);
        
        page('/:culture/:processId([0-9]+)/controldata'
                , processPage.loadProcessData
                , processPage.showControlData);
        
        page('/:culture/:processId([0-9]+)/cockpit'
                , processPage.loadProcessData
                , processPage.showCockpit);
        
        page('/:culture/:processId([0-9]+)/description'
                , processPage.loadProcessData
                , processPage.showProcessDescription);
        
        page('/:culture/:processId([0-9]+)/measureplan'
                , processPage.loadProcessData
                , processPage.showMeasurePlan);
        
        page('/:processId([0-9]+)?/controlcycle/*' , forgetRememberdVisitedPageOfTab, processPage.prepare);
        
        page('/controlcycle/create/', forgetRememberdVisitedPageOfTab, processPage.callCanonicalPath);
        
        page('/:processId([0-9]+)/controlcycle/change', forgetRememberdVisitedPageOfTab, processPage.callCanonicalPath);
        
        page('/:processId([0-9]+)/controlcycle/withdraw', forgetRememberdVisitedPageOfTab, processPage.callCanonicalPath); 
    }
    
    var loadOrganizationRouting = function() {
        
        var organizationPage = new OrganizationPage();
        
        page.base(contextPath + '/org');
        
        page('/', organizationPage.prepare
                , organizationPage.updateNavigation
                , organizationPage.welcome)
        
        page('/:culture/:orgUnitId([a-z]+|-[0-9]+)'
                , organizationPage.prepare
                , organizationPage.updateNavigation
                , organizationPage.welcome)
        
        page('/:culture/:orgUnitId([0-9]+)'
                , organizationPage.prepare
                , organizationPage.updateNavigation
                , organizationPage.updateDetails
        )
    }
    
    var loadDocumentRouting = function() {
        var documentPage = new DocumentPage();
        
        page.base(contextPath + '/document');
        
        page('/', documentPage.loadRootNavigation, documentPage.loadCanonicalPage);
        
        page('/:culture/:id(A[0-9]+)', documentPage.loadNavigation);
        page('/:culture/:id([AD][0-9]+)', documentPage.loadCanonicalPage);
        
        
        page('/controlcycle/*', forgetRememberdVisitedPageOfTab, documentPage.loadCanonicalPage);
        page('/:culture/:id([AD][0-9]+)/controlcycle/*', forgetRememberdVisitedPageOfTab, documentPage.loadCanonicalPage);
        
        page('/*', documentPage.loadCanonicalPage);
    }
    
    var loadAssociationRouting = function() {
        var associationPage = new AssociationPage();
        
        page.base(contextPath + '/assoc');
        
        page('/', associationPage.prepare
                , associationPage.updateNavigation
                , associationPage.loadCanonicalPage);
        
        page('/:culture/:type/:id([0-9]*)'
                , associationPage.prepare
                , associationPage.updateNavigation
                , associationPage.loadCanonicalPage);
    }
    
    rememberVisitedPageOfTab();
    
    if(isTab('/process')) {
        loadProcessRouting();
    }
    else {
        page(/[^/]/, function (ctx, next) {
            if(!ctx.firstLoad)
                next();
        });
        
        if(isTab('/org')) {
            loadOrganizationRouting();
        } 
        else if(isTab('/document')) {
            loadDocumentRouting();
        }
        else if(isTab('/assoc')) {
            loadAssociationRouting();
        }
        else {
            page.stop();
        }
    }

    
    page({
        click: true,
        popstate: true,
        dispatch: true
    });
});
function AssociationPage() {
    const navigationUriTemplate = new URITemplate(contextPath + '/assoc/{culture}/{type}/{id}/navigation?append={shouldAppend}');
    
    var mainSectionUpdater = new MainSectionUpdater();
    var navigation = new SubNavigation();
    
    var currentType;
    
    var cache;
    
    function init() {
        currentType = $('#sub-navigation-breadcrumb > li:first-child').data('navigation-type');
        initGroupedNavigation();
        if($('#sidebar-content-wrapper').find('li.active').length == 0) {
            navigation.select(0, currentType);
        }
    }
    init();
    
    function initGroupedNavigation() {
        cache = $('#navigation-cache').clone();
        
        const eventName = 'click.grouped.navigation';
        $('#sidebar-content-wrapper').off(eventName);
        $('#sidebar-content-wrapper').on(eventName, '[data-navigation-type=grouped]', function(e) {
            e.preventDefault();
            
            var li = $(this).closest('li');
            var index = $(this).closest('ul').find('li').index(this);
  
            index = ++index * -1;
            
            navigation.selectNode(li);
            
            if ($(li).closest('#sub-navigation-current-content').length > 0) {
                var navigationContent = cache.find('[data-id=' + index + ']').clone();
                navigationContent.removeData('id');
                $this.append(this, navigationContent);
            }
        });
    }
    
    
    return {
        prepare: function(ctx, next) {
            ctx.id = ctx.params.id || 0;
            ctx.type = ctx.params.type;
            ctx.culture = ctx.params.culture || currentCulture;
            
            if(currentType != ctx.type) {
                cache = undefined;
            }
            
            next();
        },
        
        updateNavigation: function(ctx, next) {
            navigation.select(ctx.id, ctx.type);
            
            if($(cache).length && ctx.id == 0) {
                var navigationContent = cache.find('[data-id=0]').clone();
                navigationContent.removeData('id');
                navigation.appendOrPrependWithType(0, ctx.type, navigationContent);
            }
            else if(!ctx.type || currentType != ctx.type) {
                var shouldAppend = !ctx.type 
                    || $('#sub-navigation-current-content').find('[data-id=0][data-navigation-type=' + ctx.type +']').length > 0;
                
                var url;
                
                if(!ctx.type) {
                    url = contextPath + "/assoc/navigation";
                }
                else {
                    url = navigationUriTemplate
                        .expand({
                            culture: ctx.culture,
                            id: ctx.id,
                            type: ctx.type, 
                            shouldAppend: shouldAppend
                         })
                        .replace('//', '/');
                }

                $.ajax(url)
                    .done(function(html) {
                        if(shouldAppend) {
                            navigation.appendOrPrependWithType(ctx.id, ctx.type, html);
                        }
                        else {
                            navigation.replaceWith(html);
                            navigation.select(ctx.id, ctx.type);
                        }
                        
                        initGroupedNavigation();
                    });
            }

            currentType = ctx.type;

            next();
        },
        
        loadCanonicalPage: function(ctx) {
            mainSectionUpdater.load(ctx.canonicalPath)
            
            if(ctx.id > 0) {
                navigation.select(ctx.id, ctx.type);
            }
        }
    }
}
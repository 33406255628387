function NavigationSelector(container) {
    function focusActiveNode() {
        focusIfNotInViewPort(container.find('.active'), true);
    }
    focusActiveNode();

    function focusIfNotInViewPort(selectedNode, scrollImmediately) {
        var margin = 120;
        
        var scrollContainer = selectedNode.closest('.sidebar-content');
        if(scrollContainer.length == 0) {
            return;
        }
        
        var scrollTop = scrollContainer.scrollTop();
        
        var scrollOffsetTop = scrollContainer.offset().top;
        var nodeOffsetTop = selectedNode.offset().top;
        var offsetTop = nodeOffsetTop - scrollOffsetTop;
        
        var isInViewPort = offsetTop + margin > 0 
            && offsetTop + selectedNode.height() < scrollContainer.height() - margin;

        if(!isInViewPort) {
            if(scrollImmediately) {
                scrollContainer.css('scroll-behavior', 'initial');
            }
            
            var newScrollTop = scrollTop + offsetTop - margin;
            
            scrollContainer.scrollTop(newScrollTop);
            scrollContainer.css('scroll-behavior', '');
        }
    }
    
    function select(id, type) {
        var selectedNode = $(container).find(selectorFor(id, type));
        
        return selectNode(selectedNode);
    }
    
    function selectorFor(id, type) {
        var selector = 'li[data-id=' + id + ']';
        
        if(type != undefined) {
            selector += '[data-navigation-type=' + type + ']';
        }
        
        return selector;
    }
    
    function selectNode(selectedNode) {
        selectedNode = $(selectedNode);
        
        $(container)
            .find('li.active')
            .removeClass('active');
        
        if(selectedNode.data('id') != "0" || selectedNode.data('alwaysSelectable')) { 
            openCollapsedParents(selectedNode);
            focusIfNotInViewPort(selectedNode);
            
            return selectedNode
                .trigger('syc.node.selected')
                .addClass('active')
                .length > 0;
        }
    }
    
    function openCollapsedParents(selectedNode) {
        selectedNode
            .parentsUntil(container, '.collapse')
            .collapse('show');
    }
    
    return {
        select: select,
        selectNode: selectNode,
        focusActiveNode: focusActiveNode
    }
}
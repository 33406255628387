function DocumentPage() {
    var mainSectionUpdater = new MainSectionUpdater();
    var navigation = new SubNavigation();
    
    function prepare(ctx) {
        ctx.id = ctx.params.id || 0;
    }
    
    return {
        loadRootNavigation: function(ctx, next) {
            var navigationId = $('#sub-navigation-current-content > ul').data('navigationId');
            if(navigationId === '' || navigationId > 0) {
                $.ajax(contextPath + "/document/navigation")
                    .done(function(html) {
                        navigation.appendOrPrependWithId(0, html);
                    });
            }
            next();
        },
        
        loadNavigation: function(ctx, next) {
            prepare(ctx);
            var url = ctx.canonicalPath + "expand";
            
            $.ajax(url)
                .done(function(html) {
                    navigation.appendOrPrependWithId(ctx.id, html);
                });
            
            next();
        },
        
        loadCanonicalPage: function(ctx, next) {
            prepare(ctx);
            
            mainSectionUpdater.load(ctx.canonicalPath);
        
            if(!navigation.select(ctx.id) && ctx.id != 0) {
                var navigationPath = ctx.canonicalPath + 'navigation';
                
                $.ajax(navigationPath)
                    .done(function(html) {
                        navigation.replaceWith(html);
                        navigation.select(ctx.id);
                    })
            }
        }
    }
}
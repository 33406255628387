SubNavigation = function() {
    $this = this;
    
    var contentContainer;
    var breadcrumbContainer;
    
    var navigationSelector;
    
    function init() {
        contentContainer = $('#sub-navigation-current-content');
        breadcrumbContainer = $('#sub-navigation-breadcrumb');
        
        navigationSelector = new NavigationSelector(contentContainer.add(breadcrumbContainer));
    }
    
    init();
    
    var hideNewElement = function(data) {
        return $('<div></div>')
            .append(data)
            .children()
            .addClass('d-none');
    }
    
    this.append = function(selectedNode, data) {
        if(data === undefined) {
            data = selectedNode;
            selectedNode = selectorFor(0);
        }
        
        data = $(data);
        selectedNode = $(selectedNode).closest('li').clone();
        
        data = hideNewElement(data);
        
        data.appendTo(contentContainer);
        var oldContent = contentContainer.children(':not(.d-none)')
        
        $(data).removeClass('fade-out-left');
        oldContent.removeClass('fade-out-left');
        oldContent.removeClass('fade-out-right');
        
        $(data).addClass('fade-out-right');
        
        $(data).removeClass('d-none');
        
        setTimeout(function() {
            selectedNode.appendTo(breadcrumbContainer);
            $('.sidebar-content').scrollTop(0);
            
            oldContent.addClass('fade-out-left');
            $(data).removeClass('fade-out-right');
            
            breadcrumbContainer.trigger('syc.navigation.changed');
            
            navigationSelector.selectNode(selectedNode);
        }, 25);
                    
        setTimeout(function() {
            oldContent.remove();
        }, 350);
    }
    
    this.prepend = function(selectedNode, data) {
        if(data === undefined) {
            data = selectedNode;
            selectedNode = selectorFor(0);
        }
        
        data = $(data);
        selectedNode = $(selectedNode).closest('li');
        navigationSelector.selectNode(selectedNode);
        
        if(breadcrumbContainer.children().last().is(selectedNode.closest('li'))) {
            return;
        }
        
        data = hideNewElement(data);
        data.prependTo(contentContainer);
        
        var oldContent = contentContainer.children(':not(.d-none)')
        
        $(data).addClass('fade-out-left');
        $(data).removeClass('fade-out-right');
        $(data).removeClass('d-none');
        
        setTimeout(function() {
            var removed = selectedNode.closest('li').nextAll().remove();
            if(removed.length == 0) {
                breadcrumbContainer.children().remove();
            }
            oldContent.addClass('fade-out-right');
            $(data).removeClass('fade-out-left');
            
            breadcrumbContainer.trigger('syc.navigation.changed');
        }, 25);
                    
        setTimeout(function() {
            oldContent.remove();
        }, 350);
    }

    this.appendOrPrependWithId = function(id, children, parents) {
        var selector = selectorFor(id);
        
        return $this.appendOrPrepend(selector, children, parents);
    }
    
    function selectorFor(id, type) {
        var selector = 'li[data-id=' + id + ']';
        
        if(type != undefined) {
            selector += '[data-navigation-type=' + type + ']';
        }
        else {
            selector += ':not([data-navigation-type])';
        }
        
        return selector;
    }
    
    this.appendOrPrependWithType = function(id, type, children, parents) {
        var selector = selectorFor(id, type);
        
        return $this.appendOrPrepend(selector, children, parents);
    }
    
    this.appendOrPrepend = function(selector, children, parents) {        
        if(breadcrumbContainer.children().length == 0 && contentContainer.find('ul > li').length == 0) {
            return fillImmediately(selector, children, parents);
        }
        
        var node = breadcrumbContainer.find(selector);
        if(node.length > 0) {
            this.prepend(node, children);
            return;
        }
        
        node = contentContainer.find(selector);
        if(node.length > 0) {
            this.append(node, children);
            return;
        }

        if(parents && parents.trim() || breadcrumbContainer.children().length == 0) {
            return fillImmediately(selector, children, parents);
        }
        
        return this.prepend(children);
    }
    
    function fillImmediately(selector, children, parents) {
        contentContainer.html(children);
        breadcrumbContainer.html(parents);
        
        breadcrumbContainer.trigger('syc.navigation.changed');
        
        return selectNode(selector);
    }
    
    function select(id, type) {
        return navigationSelector.select(id, type);
    }
    
    function selectNode(element) {
        return navigationSelector.selectNode(element);
    }
    
    function replaceWith(html) {
        $('#sidebar-content-wrapper').html(html);
        init();
        
        breadcrumbContainer.trigger('syc.navigation.changed');
    }
    
    return {
        append: this.append,
        prepend: this.prepend,
        appendOrPrependWithId: this.appendOrPrependWithId,
        appendOrPrependWithType: this.appendOrPrependWithType,
        select: select,
        selectNode: selectNode,
        replaceWith: replaceWith
    }
}
$(function() {
    $(window).on('blur', function() {
        $('.dropdown.show > [data-toggle=dropdown]').dropdown('toggle');
    });
    
    $(document).on('hide.bs.dropdown', function(e) {
        if(document.hasFocus() 
                && $.contains(e.target, document.activeElement) 
                && !$(e.target).children('[data-toggle=dropdown]').is(document.activeElement)) {
            return false;
        }
    });
});
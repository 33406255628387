StyleUtils = {
    waitForNewHeight: function(columnEntry, collapsible, callback) {
        var observer = new MutationObserver(function(mutations) {
            var mutation = mutations[0];
            if (mutation.attributeName === 'style') {
                var targetHeight = StyleUtils.convertToPx(StyleUtils.getStyle(collapsible, 'height'));
                var newEntryHeight = $(columnEntry).outerHeight() - $(collapsible).height() + targetHeight;
                
                callback(columnEntry, newEntryHeight);
                
                observer.disconnect();
                observer = undefined;
            }
        });
        
        observer.observe(collapsible, {
            attributes: true,
            attributeFilter: ["style"],
            attributeOldValue: false
        });
    },
    
    getStyle: function(node, styleProp) {
        if(!node || node === document) {
            return '';
        }
        
        var property = node.style ? node.style[styleProp] : '';
        if(property) {
            return property;
        }
        
        var sheets = document.styleSheets;
        node.matches = node.matches || node.webkitMatchesSelector || node.mozMatchesSelector || node.msMatchesSelector || node.oMatchesSelector;
        
        for (var sheet in sheets) {
            var rules = sheets[sheet].rules || sheets[sheet].cssRules;

            for (var i = rules.length - 1; i >= 0; i--) {
                var rule = rules[i];
                if (node.matches(rule.selectorText)) {
                    var property = rule.style[styleProp];
                    if(property) {
                        return property;
                    }
                }
            }
        }
        
        if(node.parentNode) {
            return getStyle(node.parentNode, styleProp);
        }
    },
    
    convertToPx: function(value) {
        if(value.endsWith('rem')) {
            value = parseFloat(value);
            value = value * parseFloat(getComputedStyle(document.documentElement).fontSize);
            
            return value;
        } 
        
        return parseFloat(value);
    }
}
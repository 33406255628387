$(function() {
    //hide other collapse-menus in navbar when one is opened / accordion style
    var selector = 'header, .side-panel';
    
    $(document).on('show.bs.collapse', 'header, .side-panel', function() {
        $('header').add('.side-panel')
            .find('.collapse')
            .addBack('.collapse')
            .not(this)
            .collapse('hide');
    })
});



$(function() {
    var makeTooLargeCollapsiblesToggleable = function(element) {
        element = $(element || document);
        element.find('.fadeout.collapsible').filter(function() {
            return this.scrollHeight > this.offsetHeight
        })
        .removeClass('collapsible')
        .addClass('collapse');
        
        element.find('.fadeout.collapse:not(.show)').filter(function() {
            return this.scrollHeight <= this.offsetHeight
        })
        .removeClass('collapse')
        .addClass('collapsible');
    }
    
    var observeMainContainer = function() {
        var observer = new MainContainerMutationObserver(function(mutations) {
            mutations.forEach(function(mutation) {
                mutation.addedNodes.forEach(function(node) {
                    if(!(node instanceof SVGElement) && node.nodeType == 1) {
                        makeTooLargeCollapsiblesToggleable(node);
                    }
                })
            });
        });
        
        var config = {
                childList: true,
                subtree: true
        };
        
        observer.observe(config);
    }
    observeMainContainer();
    
    makeTooLargeCollapsiblesToggleable();
    
    var makeTooLargeCollapsiblesToggleableCallback = function(event) {
        makeTooLargeCollapsiblesToggleable(event.target);
    }
    
    $(window).on('resize', function(event) {
        makeTooLargeCollapsiblesToggleable(event.target);
    });
    
    $(document).on('shown.bs.tab', function(event) {
        makeTooLargeCollapsiblesToggleable($(event.target).data('target'));
    });
    
    $(document).on('click', '.fadeout.collapse:not(.show)', function(e) {
        $(e.target).collapse('show');
    });
})

$(function() {
    var collapse = function(event) {
        var parents = $(event.target).parents('.collapse[data-hide-on-lost-focus=true]').toArray();
        
        $('.collapse[data-hide-on-lost-focus=true]').not(parents).collapse('hide');
    }
    
    $(document).on('click', collapse);
    $(window).on('blur pagechange', collapse);
    
})
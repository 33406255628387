(function ($) {
    $.fn.snapLeft = function() {
        return this
            .addClass('snapped-left')
            .removeClass('snapped-right')
            .css('right', '')
            .css('left', '');
    };

    $.fn.snapRight = function() {
        return this
            .addClass('snapped-right')
            .removeClass('snapped-left')
            .css('right', '')
            .css('left', '');
    };
    
    $.fn.snapTop = function() {
        return this
            .addClass('snapped-top')
            .removeClass('snapped-bottom')
            .css('top', '')
            .css('bottom', '');
    };
    
    $.fn.snapBottom = function() {
        return this
            .addClass('snapped-bottom')
            .removeClass('snapped-top')
            .css('top', '')
            .css('bottom', '');
    };
    
    $.fn.unsnapX = function() {
        return this
            .removeClass('snapped-left')
            .removeClass('snapped-right');
    };
    
    $.fn.unsnapY = function() {
        return this
            .removeClass('snapped-top')
            .removeClass('snapped-bottom');
    };
}(jQuery));
/*
 * toggle Favourites
 */
$(function() {
    var baseUrl = contextPath + "/favourites/change/";

    $(document).on('click', '.favourite-button', function(event) {
        event.preventDefault();
                
        var $this = $(this);
        var id = $this.data('document-id');

        var targetValue = !$this.data('is-favourite');

        var url = baseUrl + id + "/" + targetValue;

        var toggleFavourite = function(data) {
            if (data.success) {
                $this
                    .toggleClass("true")
                    .toggleClass("false");
                
                $this.data('is-favourite', data.isFavourite);
                $this.children('.link-caption').text(resourceBundle.get('de.cim_house.sycat.dokweb.documents.icon.favourite.' + data.isFavourite));
            }
        };

        $.ajax({
            dataType : "json",
            type : "POST",
            url : url
        }).done(toggleFavourite);
        
        $this.tooltip('hide');
    });
});
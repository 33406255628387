$(function() {
    $(document).on('show.bs.collapse hide.bs.collapse', '.multi-column', function(e) {
        
        var collapsible = e.target;
        var columnEntry = $(collapsible).closest('.multi-column-entry')[0];
        
        StyleUtils.waitForNewHeight(columnEntry, collapsible, determineDimensionsAfterResize);
    })
        
    function determineDimensionsAfterResize(entryNode, newEntryHeight) {
        var container = $(entryNode).closest('.multi-column');
        
        var grid = new Grid(container[0]);
        
        var entries = [];
        container.children('.multi-column-entry')
            .each(function() {
                var height;
                if(this == entryNode) {
                    height = newEntryHeight;
                }
                else {
                    height = $(this).outerHeight();
                }
                
                grid.add(this, height);
            });
        
        grid.rearrange();
    }
    
    function Grid(container, entries) {
        var $this = this;
        var entries = [];
        
        var containerOffset = $(container).offset();
        var containerWidth;
        
        var columns;
        var movingEntriesCount = 0;
        
        (function initColumns() {
            var style = window.getComputedStyle(container);
            var columnCount = parseInt(style.columnCount);
            var gap = StyleUtils.convertToPx(style.columnGap);
            
            containerWidth = $(container).width();
            columnWidth = containerWidth / columnCount - gap / 2;
            
            columns = [];
            
            for(var i = 0; i < columnCount; i++) {
                column = new Column();
                var columnLeft = (columnWidth + gap) * i;
                column.setLeft(columnLeft);
                column.setWidth(columnWidth);
                
                columns.push(column);
            }
        })();
                
        function add(element, height) {
            var offset = $(element).offset().left - containerOffset.left;
            var column = offset == 0 ? 0 : Math.floor(containerWidth / offset);
              
            var entry = new ColumnEntry(element, height)
            columns[column].push(entry);
            entry.column = columns[column];
            
            entries.push(entry);
        }
        
        function height() {
            var height = 0;
            columns.forEach(function(column) {
                height = Math.max(height, column.getHeight());
            })
            
            return height;
        }
        
        function rearrange() {
            if(columns.length <= 1) {
                return;
            }
            
            predictNewLayout();
            
            if(movingEntriesCount > 0) {
                moveEntriesToNewPosition();
            }
        }
        
        function predictNewLayout() {
            var newHeight = height();
            
            for(var i = 0; i < columns.length - 1; i++) {
                var left = columns[i];
                var right = columns[i + 1];
                
                do {
                    var entry = right.getFirst();
                    
                    var leftHeight = left.getHeight() + entry.height();
                    var rightHeight = right.getHeight() - entry.height();
                    
                    var possibleHeight = Math.max(leftHeight, rightHeight);
    
                    if(possibleHeight < newHeight) {
                        right.shift();
                        left.push(entry);
                        newHeight = possibleHeight;
                        movingEntriesCount++;
                        entry.willChangeColumn = true;
                    }
                    else {
                        break;
                    }
                } while (true);
                
                do {
                    var entry = left.getLast();
                    var leftHeight = left.getHeight() - entry.height();
                    var rightHeight = right.getHeight() + entry.height();
                    
                    var possibleHeight = Math.max(leftHeight, rightHeight);
                    
                    if(possibleHeight < newHeight) {
                        left.pop();
                        right.unshift(entry);
                        newHeight = possibleHeight;
                        movingEntriesCount++;
                        entry.willChangeColumn = true;
                    }
                    else {
                        break;
                    }
                } while(true);
            }
        }
        
        function moveEntriesToNewPosition() {
            var transitioningElements = 0;
            
            transitionEndCallbacks = [];
            
            for(var i = 0; i < columns.length; i++) {
                var first = columns[i].getFirst();
                if(first.willChangeColumn) {
                    first.onTransitionStart(function() {
                        addPrimaryBar(this);
                    })
                    
                    var next = first.next;
                    while(next && next.willChangeColumn) {
                        next = next.next;
                    }
                    
                    if(next) {
                        addPrimaryBar(next);
                        
                        next.onTransitionStart(function() {
                            removePrimaryBar(this);
                        })
                    }
                }
                else {
                    first.onTransitionStart(function() {
                        addPrimaryBar(this);

                    })
                }
                var prev = new columns[i].getLast();
                while(prev.prev && prev.prev.willChangeColumn) {
                    prev = prev.prev;
                }
                if(prev.willChangeColumn) {
                    addPrimaryBar(prev);
                    prev.onTransitionStart(function() {
                        removePrimaryBar(this);
                    })
                }
            }
            
            entries.forEach(function(entry) {
                entry.startMoving();
            });
            
            setTimeout(function() {
                beginTransition();
                
                entries.forEach(function(entry) {
                    if(entry.willChangePosition()) {
                        transitioningElements++;    
                    }
                    
                    transitionEndCallbacks.push(entry.finishMoving);
                    entry.onTransitionEnd(finishTransition);
                    
                    entry.move();
                });
            }, 1);

            function beginTransition() {
                container.classList.add('transitioning');
            }
            
            function finishTransition() {
                if(--transitioningElements == 0) {
                    container.classList.remove('transitioning');
                    
                    transitionEndCallbacks.forEach(function(callback) {
                        callback();
                    })
                };
            }
            
            function addPrimaryBar(entry) {
                var classList = entry.element.classList;
                classList.add('primary-bar-top');
                transitionEndCallbacks.push(function() {
                    classList.remove('primary-bar-top');
                })
            }
            
            function removePrimaryBar(entry) {
                entry.element.classList.remove('primary-bar-top');
            }
        }
        
        function Column(left, width) {
            var entries = [];
            
            var height = 0;
            
            var offset;
            
            function add(entry, column) {
                height += entry.height();
                entry.column = column;
            }
            
            function remove(entry) {
                height -= entry.height();
                entry.prev = undefined;
                entry.next = undefined;
                entry.column = undefined;
            }
            
            function getEntries() {
                return entries;
            }
            
            function getHeight() {
                return height;
            }
            
            function getWidth() {
                return width;  
            }
            
            function setWidth(newWidth) {
                width = newWidth;
            }
            
            function getLeft() {
                return left;  
            }
            
            function setLeft(newLeft) {
                left = newLeft;
            }
            
            function push(entry) {
                entry.prev = this.getLast();
                entry.next = undefined;
                add(entry, this);
                entries.push(entry);
            }
            
            function pop() {
                var entry = entries.pop(); 
                remove(entry);
                
                return entry;
            }
            
            function shift() {
                entry = entries.shift();
                remove(entry);
                
                if(entries.length > 0) {
                    getFirst().prev = undefined;
                }
                
                return entry;
            }
            
            function unshift(entry) {
                if(entries.length > 0) {
                    this.getFirst().prev = entry;
                }
                if(entries.length > 1) {
                    entry.next = this.getFirst();
                }
                add(entry, this);
                entries.unshift(entry);
            }
            
            function getFirst() {
                return entries.length > 0 ? entries[0]: undefined;
            }
            
            function getLast() {
                return entries.length > 0 ? entries[entries.length - 1]: undefined; 
            }
            
            return {
                add: add,
                remove: remove,
                getEntries: getEntries,
                getHeight: getHeight,
                getWidth: getWidth,
                setWidth: setWidth,
                getLeft: getLeft,
                setLeft: setLeft,
                push: push,
                pop: pop,
                shift: shift,
                unshift: unshift,
                getFirst: getFirst,
                getLast: getLast
            }
        }
        
        function ColumnEntry(element, height) {
            var $this = this;
            
            this.element = element;
            this.column;
            this.prev;
            this.next;
            
            this.willChangeColumn = false;
            
            var offsetOrig = $(element).offset();
            var widthOrig = $(element).width();
            
            this.top = function() {
                if(!this.prev) {
                    return 0;
                }
                return this.prev.top() + this.prev.height();
            }
            
            this.left = function() {
                return this.column ? this.column.getLeft() : 0;
            }
            
            this.height = function() {
                return height;
            }
            
            this.width = function() {
                return this.column ? this.column.getWidth() : 0;
            }
            
            this.willChangePosition = function() {
                return !(offsetOrig.left - containerOffset.left == this.left() 
                        && offsetOrig.top - containerOffset.top == this.top());
            }
            
            this.startMoving = function() {
                $(element).css({
                    left: offsetOrig.left - containerOffset.left,
                    top: offsetOrig.top - containerOffset.top,
                    width: widthOrig,
                    position: 'absolute'
                })
            }
            
            this.move = function() {
                fireTransitionStart();
                $(element).css({
                    top: this.top(),
                    left: this.left(),
                })
            }
            
            this.finishMoving = function() {
                $(element).css({
                    left: '',
                    top: '',
                    width: '',
                    position: ''
                })
            }
            
            var transitionStartEvents = [];
            function fireTransitionStart() {
                transitionStartEvents.forEach(function(callback) {
                    callback.apply($this);
                })
            }
            
            this.onTransitionStart = function(callback) {
                transitionStartEvents.push(callback);
            }

            this.onTransitionEnd = function(callback) {
                $(element).one('transitionend', callback);
            }
        }
        
        return {
            rearrange: rearrange,
            add: add
        }
    }
})

function getScrollBarWidth () {
  var inner = document.createElement('p');
  inner.style.width = "100%";
  inner.style.height = "200px";

  var outer = document.createElement('div');
  outer.style.position = "absolute";
  outer.style.top = "0px";
  outer.style.left = "0px";
  outer.style.visibility = "hidden";
  outer.style.width = "200px";
  outer.style.height = "150px";
  outer.style.overflow = "hidden";
  outer.appendChild (inner);

  document.body.appendChild (outer);
  var w1 = inner.offsetWidth;
  outer.style.overflow = 'scroll';
  var w2 = inner.offsetWidth;
  if (w1 == w2) w2 = outer.clientWidth;

  document.body.removeChild (outer);

  return (w1 - w2);
};
function PageOfTabRememberer() {
    function getCurrentTabKey() {
        return "lastPage#" + page.base();
    } 
    
    function removeAll() {
        Object.keys(sessionStorage)
            .filter(function(key) {
                return key.startsWith('lastPage#')
            })
            .forEach(function(key) {
                sessionStorage.removeItem(key);
            });
    }
    
    (function registerEvents() {
        document.addEventListener('syc.language.changed', removeAll);
    })();
    
    return {
        getLastPage: function() {
            var key = getCurrentTabKey();
            var href = sessionStorage.getItem(key);
            
            return href; 
        },
        
        setLastPage: function(path) {
            var key = getCurrentTabKey();
            sessionStorage.setItem(key, path);
        },
        
        removeLastPage: function() {
            var key = getCurrentTabKey();
            sessionStorage.removeItem(key);
        }
    }
}

var pageOfTabRememberer = new PageOfTabRememberer();
var notificationBox;
$(function() {
    var NotificationBox = function() {
        var container = $('#notification-box');

        this.appendMessage = function(type, message) {
            var newMessage = $('<div class="alert alert-dismissable fade show"></div>');
            newMessage.addClass('alert-' + type);
            newMessage.append($('<a href="javascript:void(0)" class="close" data-dismiss="alert" aria-label="close"><span class="mdi mdi-close"></span></a>'));
            newMessage.append(message);

            container.append(newMessage);
        }
    };

    notificationBox = new NotificationBox();
})
(function ($) {
    $.fn.outerHeightSum = function(includeMargin) {
        var height = 0;
        
        this.each(function() {
            height += $(this).outerHeight(includeMargin);
        })
        
        return height;
    };

}(jQuery));
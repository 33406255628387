(function ($) {
    $.fn.disableSelection = function() {
        return this.addClass('unselectable')
        .attr('unselectable', 'on')
        .on('selectstart', false);
    };

    $.fn.enableSelection = function() {
        return this.removeClass('unselectable')
        .removeAttr('unselectable')
        .off('selectstart');
    };
}(jQuery));
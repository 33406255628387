function MainContainerMutationObserver(callback) {
    var observer = new MutationObserver(callback);
    
    return {
        observe: function(config) {
            var main = document.getElementById('main');
            if(main !== undefined && main !== null) {
                observer.observe(main, config);
            }
        } 
    }
}
function ProgressBar(progressContainer) {
    const minProgress = 10;
    
    var _progressValue;
    var _resetTimeoutHandler;
    
    var _currentValue;
    
    reset();

    function reset() {
        clearTimeout(_resetTimeoutHandler);
        
        _currentValue = 0;
        
        $(progressContainer).addClass('d-none');
        
        _progressValue = $('<div class="progress-bar"></div>')
        $(progressContainer).empty().append(_progressValue);
        _progressValue.width(0);
    }
    
    function start() {
        $(progressContainer).removeClass('hidden').removeClass('d-none');
        _progressValue.width(minProgress + '%');
    }
    
    function update(value, max) {
        if(_currentValue == value) {
            return;
        }
        _currentValue = value;

        max = Math.max(0, max || 100);
        value = Math.min(max, Math.max(0, value));
        
        var width = Math.max(minProgress, value / max * 100);
        
        _progressValue.width(width + '%');
    }
    
    function finish() {
        _progressValue.width('100%');
        
        _progressValue.one('transitionend', function(event) {
            _resetTimeoutHandler = setTimeout(reset, 100);
        });
        
    }
    
    return {
        start: start,
        reset: reset,
        update: update,
        finish: finish
    }
}
$(function() {
    $('#navbar-searchbox').on('shown.bs.collapse', function() {
        $(this).find('input').first().focus();
    });

    $('#searchbox .searchbox-container').autocomplete({

        delay : 200,
        queryField: $('input[name=q]'),
        resultContainer : $('.searchbox-results'),
        template: '#search-result-template',
        data : {
            remote : {
                ajax : {
                    url : contextPath + "/quicksearch?q={{query}}"
                }
            },
            defaults: {
                groups: {
                    Document: {
                        resourceKey: 'de.cim_house.sycat.dokweb.tabs.docs'
                    },
                    Process: {
                        resourceKey: 'de.cim_house.sycat.dokweb.tabs.processes'
                    },
                    Association: {
                        resourceKey: 'de.cim_house.sycat.dokweb.tabs.assocs'
                    },
                    OrganisationUnit: {
                        resourceKey: 'de.cim_house.sycat.dokweb.tabs.employees'
                    },
                    ITSystem: {
                        resourceKey: 'de.cim_house.sycat.dokweb.process.details.editing.systems'
                    },
                    KeyFigure: {
                        resourceKey: 'de.cim_house.sycat.dokweb.process.details.key.figures'
                    },
                    Information: {
                        resourceKey: 'de.cim_house.sycat.dokweb.process.details.informations'
                    },
                    Module: {
                        resourceKey: 'de.cim_house.sycat.dokweb.process.details.modules'
                    },
                    Resource: {
                        resourceKey: 'de.cim_house.sycat.dokweb.process.details.resources'
                    },
                    Feature: {
                        resourceKey: 'de.cim_house.sycat.dokweb.process.details.features'
                    },
                    Location: {
                        resourceKey: 'de.cim_house.sycat.dokweb.document.details.location'
                    },
                    Tag: {
                        resourceKey: 'de.cim_house.sycat.dokweb.document.details.tags'
                    }
                }
            },

            filter : function(data) {
                $.each(data.groups, function(index, group) {
                    if(!group.entries) {
                        delete data.groups[index];
                    }
                });

                return data;
            }
        },
    });

    //focus the search input field when opend in smartphone view
    $('#navbar-searchbox').on('shown.bs.collapse', function() {
        $(this).find('input').first().focus();
    });
});
$(function() {
    $("#change_language_form button[type=reset]").click(function(event){
        event.preventDefault();
        window.history.back();
    });
    
    $("#change_language_form select").change(function(event){
        var selectedCountryCode =  $(this).find(":selected").attr("id");
        var languagesToSelect = $("#change_language_form input[type=radio]");
        var languageToSelect = languagesToSelect.filter(function(index){
            return $(this).attr("id") == selectedCountryCode + "~" + selectedCountryCode;
        });
        if (!languageToSelect.length) {
            languageToSelect = languagesToSelect.filter(function(index){
                return $(this).attr("id").startsWith(selectedCountryCode + "~");
            });
        }
        languageToSelect.prop("checked", true);
    });
    
    $("#change_language_form input[name=userLanguages]").change(function() {
        var uiLanguage = $(this).attr('id').split("~")[0];
        $("#" + uiLanguage).prop('selected', 'true');
    })
});
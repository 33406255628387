$(function() {
    /* Set the defaults for DataTables initialisation */

    var normalizeAccentsAndUmlauts = function(data) {

        if(!data || typeof data !== 'string')
            return data;

        return data
        .replace( /έ/g, 'ε' )
        .replace( /[ύϋΰ]/g, 'υ' )
        .replace( /ό/g, 'ο' )
        .replace( /ώ/g, 'ω' )
        .replace( /ά/g, 'α' )
        .replace( /[ίϊΐ]/g, 'ι' )
        .replace( /ή/g, 'η' )
        .replace( /\n/g, ' ' )
        .replace( /á/g, 'a' )
        .replace( /é/g, 'e' )
        .replace( /í/g, 'i' )
        .replace( /ó/g, 'o' )
        .replace( /ú/g, 'u' )
        .replace( /ê/g, 'e' )
        .replace( /î/g, 'i' )
        .replace( /ô/g, 'o' )
        .replace( /è/g, 'e' )
        .replace( /ï/g, 'i' )
        .replace( /ü/g, 'u' )
        .replace( /ã/g, 'a' )
        .replace( /õ/g, 'o' )
        .replace( /ç/g, 'c' )
        .replace( /ì/g, 'i' )
        .replace( /ä/g, 'a' )
        .replace( /ö/g, 'o' )
        .replace( /ü/g, 'u' )

    };

    var defaultStringPre = $.fn.dataTable.ext.type.order['string-pre'];

    $.fn.dataTable.ext.type.order['string-pre'] = function(data) {
        var data = defaultStringPre(data); 
        return normalizeAccentsAndUmlauts(data);
    };  

    var defaultHtmlPre = $.fn.dataTable.ext.type.order['html-pre'];

    $.fn.dataTable.ext.type.order['html-pre'] = function(data) {
        var data = defaultHtmlPre(data); 
        return normalizeAccentsAndUmlauts(data);
    };
    
    function initTables(element) {
        $('.table.data-table', (element || document))
        .filter(function(element) {
            var isDataTable = $.fn.dataTable.isDataTable(element);
            return !isDataTable;
        })
        .DataTable({
            "paging" : false,
            "info" : false,
            "searching" : false,
            "autoWidth": false
        });
    }
    
    initTables();

    
    (function() {
        var observer = new MainContainerMutationObserver(function(mutations) {
            initTables(main);
        });
        
        var config = {
                childList: true,
                subtree: false
        };
        
        observer.observe(config);
    })();
});
$(document).on('click', '.image-container a', function(event) {
    event.preventDefault();
     
    $(this).magnificPopup({
        type : 'image',
        
        closeBtnInside: true,
        closeOnContentClick: true,
        tClose: '',
        
        image: {
            cursor: null,
        },
        zoom: {
            enabled: false
        }
    })
    .magnificPopup('open');
});
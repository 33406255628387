$(document).on('show.bs.dropdown', '[data-language-switch]', function(e) {
    var currentCulture = $(e.target).data('current-culture');
    var currentUri = URI(window.location.href);
    var currentPath = currentUri.pathname();
    $('.dropdown-menu a', e.target).each(function() {
        var targetCulture = $(this).data('culture'); 
        var newPath = currentPath.replace('/' + currentCulture + '/', '/' + targetCulture + '/');
        var newUri = currentUri.pathname(newPath);
        
        $(this).attr('href', newUri.toString());
    })
});

$(function() {
  function registerAutocomplete(element) {
    console.log($(element)
      .find('[data-org-unit-search]'));

    $(element)
      .find('[data-org-unit-search]')
      .autocomplete({
      delay: 200,
      minLength: 2,
      queryField: $('[data-org-unit-search-query]'),
      resultContainer: $('[data-org-unit-search-results]'),
      template: '#org-search-result-template',
      data: {
        remote: {
          ajax: {
            url: contextPath + "/api/de-DE/org/?filter={{query}}"
          }
        },
      },
    });
  }
  
  registerAutocomplete($('#external-reports-config'));

  $('#external-reports-config').on('click', '[data-org-unit-search] [data-org-unit-search-results] .dropdown-item', (e) => {
    let target = $(e.target);
    let name = target.text();
    let orgId = target.data('orgId');

    let results = target.closest('[data-org-unit-search-results]');

    results.siblings('[data-org-unit-search-query]').val(name);
    results.siblings('[data-org-unit-id-value]').val(orgId);
    target.blur();
  });
  
  $('#external-reports-config').on('change', '[data-org-unit-search-query]', (e) => {
    if($(e.target).val().trim() == '') {
      $(e.target).siblings('[data-org-unit-id-value]').val(null);
    }
  });

  $('#external-reports-config').on('click', '[data-remove-entry]', (e) => {
    $(e.target)
      .closest('[data-org-unit-search]')
      .remove();
  });

  const entryTemplate = Handlebars.compile($('#external-reports-add-org-template').html());

  $('#external-reports-config').on('click', '[data-add-entry]', (e) => {
      const pageKey = $(e.target).data('pageKey');
      
      const entries = $(e.target).prev('[data-entries]');
      const entry = entryTemplate({pageKey: pageKey});
      const newEntry = entries.append(entry);

      registerAutocomplete(newEntry);
  });
});
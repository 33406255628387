$(function() {
    const breadcrumbSelector = '#sub-navigation-breadcrumb';
    const contentSelector = '#sub-navigation-current-content';
    
    function browserSupportsPositionSticky() {
        var breadcrumb = document.getElementById('sub-navigation-breadcrumb');
        var position = window.getComputedStyle(breadcrumb).position;
        
        return position == 'sticky';
    }
    
    if($(breadcrumbSelector).length) {
        if(browserSupportsPositionSticky()) {
            startStickyBreadcrumbObserver();
        }
        else {
            startPseudoStickyObserver();
        }
    }
    
    function startStickyBreadcrumbObserver() {
        function calculateContentTop() {
            var breadcrumb = $(breadcrumbSelector);
            
            var lastThreeElements = breadcrumb
                .children('li')
                .slice(-3);
            
            var top = 0;
            if(lastThreeElements.length >= 3) {
                var heightOfLastThreeElements = lastThreeElements.outerHeightSum(true);
                var halfHeightOfElement = lastThreeElements.first().outerHeight(true) / 2;
                
                top = -breadcrumbHeight() + (heightOfLastThreeElements - halfHeightOfElement);
            }

            breadcrumb.css('top', top);
            
            var navigationHeader = $('.navigation-header');
            
            var sideBarContent = $('#sidebar .sidebar-content'); 
            
            sideBarContent.off('scroll.shadow');
            sideBarContent.on('scroll.shadow', throttle(determineWetherShadowsShouldBeShown, 50));

            var headerShadow = navigationHeader.hasClass('scroll-shadow-on');;
            var breadcrumbShadow = breadcrumb.hasClass('scroll-shadow-on');
            
            function determineWetherShadowsShouldBeShown() {
                if(top < 0 || $(this).find('.sub-navigation-current-content').length == 0) {
                    if(this.scrollTop > 0) {
                        if(!headerShadow) {
                            navigationHeader.addClass('scroll-shadow-on');
                            headerShadow = true;
                        }
                    }
                    else {
                        if(headerShadow) {
                            navigationHeader.removeClass('scroll-shadow-on');
                            headerShadow = false;
                        }
                    }
                }
                
                if(this.scrollTop > Math.abs(top)) {
                    if(!breadcrumbShadow) {
                        breadcrumb.addClass('scroll-shadow-on');
                        breadcrumbShadow = true;
                    }
                }
                else { 
                    if (breadcrumbShadow) {
                        breadcrumb.removeClass('scroll-shadow-on');
                        breadcrumbShadow = false;
                    }
                }
            }
            
            sideBarContent.each(determineWetherShadowsShouldBeShown);
        }
        
        startAndBindObserver(calculateContentTop);
    }
    
    function breadcrumbHeight() {
        return $(breadcrumbSelector).outerHeight(true);
    }
    
    function startAndBindObserver(calculateContentTop) {
        $(document).on('syc.navigation.changed', breadcrumbSelector, calculateContentTop);
        
        $(document).on('shown.bs.tab', '#sidebar', function(e) {
            if($('.tab-pane.active', $(this)).find(breadcrumbSelector).length == 0) {
                $('.navigation-header').removeClass('scroll-shadow-on');
            }
            else {
                calculateContentTop();
            }
        });
        
        calculateContentTop();
    }
    
    function startPseudoStickyObserver() {
        function calculateContentTop() {
            var navigationHeader = $('.navigation-header');
            var breadcrumb = $(breadcrumbSelector);
            var content = $(contentSelector);
            
            breadcrumb.removeClass('pseudosticky');
            content.removeClass('pseudosticky');
            
            breadcrumb.removeClass('scroll-shadow-on');
            navigationHeader.removeClass('scroll-shadow-on');
            
            $(contentSelector).css('top', '');
            
            var sc = $('.sidebar-content:visible');
            if(sc.height() < sc[0].scrollHeight) {
                breadcrumb.addClass('pseudosticky');
                content.addClass('pseudosticky');
                
                navigationHeader.toggleClass('scroll-shadow-on', breadcrumb.children('li').length >= 3);
                
                $(contentSelector).css('top', breadcrumbHeight());
                
                
                content.off('scroll.shadow');
                content.on('scroll.shadow', throttle(determineWetherShadowsShouldBeShown, 50));

                function determineWetherShadowsShouldBeShown() {
                    breadcrumb.toggleClass('scroll-shadow-on', this.scrollTop > 0);
                }
                content.each(determineWetherShadowsShouldBeShown);
            }
        }
        
        startAndBindObserver(calculateContentTop);
    }
})
$(function() {
   $('.color-picker').each(function() {
     var input = $(this).find('input');
     var button = $(this).find('.color-picker-btn');
     
     bindInputToColorPicker(input, button);
   }); 
   
   function bindInputToColorPicker(input, button) {
       var color = input.val();
       
       button.colorpicker({
          format: 'hex',
          color: color 
       }).on('colorpickerChange', function(e) {
             input.val(e.color);
       });
       
       input.on('change', function(e) {
           var picker = button.colorpicker('colorpicker'); 
           picker.setValue(this.value);
       });
   } 
});
function Datepicker(element) {
    moment.locale(currentGuiCulture);
    var localeData = moment.localeData();
    
    var currentValueOf = function(element) {
        var valueField = $($(element).data('targetSubmit'));
        var dateValue = valueField.val();

        if(dateValue > 0)
            return new Date(parseInt(dateValue));
    }
    
    
    var $this = $(element);

    var valueField = $($this.data('targetSubmit'));

    var defaultDate = currentValueOf($this);

    var boundedStartDatePicker = $($this.data('bindStartWith'));
    var boundedEndDatePicker = $($this.data('bindEndWith'));

    var isStartDatePicker = function() {
        return boundedEndDatePicker.length > 0;
    }
    
    var isEndDatePicker = function() {
        return boundedStartDatePicker.length > 0;
    }
    
    var updateStartDate = function() {
        if(isEndDatePicker()) {
            var date = picker.getDate();
            var startPicker = boundedStartDatePicker.data('datepicker') || new Datepicker(boundedStartDatePicker[0]);
            startPicker.setMaxDate(date);
            if(startPicker.getDate() == null) {
                startPicker.gotoDate(date);
            }
        } 
    }
    
    var updateEndDate = function() {
        if(isStartDatePicker()) {
            var date = picker.getDate();
            var endPicker = boundedEndDatePicker.data('datepicker') || new Datepicker(boundedEndDatePicker[0]);
            endPicker.setMinDate(date);
            if(endPicker.getDate() == null) {
                endPicker.gotoDate(date);
            }
        } 
    }
    
    var picker = new Pikaday({
        field: element,
        defaultDate: defaultDate,
        format: localeData.longDateFormat('L'),
        firstDay: localeData.firstDayOfWeek(),

        position: 'left-aligned',
        i18n: {
            months        : localeData.months(),
            weekdays      : localeData.weekdays(),
            weekdaysShort : localeData.weekdaysShort()
        },
        onSelect: function() {
            valueField.val(picker.getMoment().valueOf());
            updateStartDate();
            updateEndDate();
        }
    });
    
    $this.data('datepicker', picker);
    
    picker.setDate(defaultDate);
    
    $this.on('change', function(e) {
        if($(this).val().trim() == '' && picker.getDate() != null) {    
            picker.setDate(null);
            valueField.val('');
            updateStartDate();
            updateEndDate();
        }
    });
    
    $(window).on('resize', function() {
        picker.hide();
    });
    
    return picker;
}

$(function() {
    function initDatePickers() {
        $('[data-toggle="datepicker"]').each(function(index, element) {
            if(!$(element).data('datepicker')) {
                new Datepicker(element);    
            }
        });
    }
    
    initDatePickers();
    
    (function() {
        var observer = new MainContainerMutationObserver(initDatePickers);
        
        var config = {
                childList: true,
                subtree: false
        };
        
        observer.observe(config);
    })();
});

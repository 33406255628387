(function ($) {
    const WRAPPER_NAME = 'loading-spinner-wrapper';
    
    $.fn.loadingSpinner = function(params) {
        this.addClass(WRAPPER_NAME);
        
        if(params == 'destroy') {
            destroy(this);
        }
        else {
            create(this, params);
        }
    };
    
    function destroy(element) {
        $(element).each(function() {
            var containerNode = this;
            var spinner = $(containerNode).data('spinner');
            
            containerNode.removeChild(spinner);
        });
    }
    
    function create(element, size) {
        $(element).each(function() {
            var containerNode = this;
            var spinner = createSpinner(size); 
            
            containerNode.appendChild(spinner);
            $(containerNode).data('spinner', spinner);
            
            startRemoveObserver(containerNode, spinner);
        });
    }
    
    function createSpinner(size) {
        size = size || 'lg';
        
        var spinner = document.createElement('div');
        spinner.className = 'loading-spinner loading-spinner-' + size;
       
        for(var i = 1; i <= 3; i++) {
            var bounce = document.createElement('div');
            bounce.className = 'spinner-bounce-' + i;
            spinner.appendChild(bounce);
        }
        
        return spinner;
    }

    
    function startRemoveObserver(containerNode, spinner) {
        var observer = new MutationObserver(function(mutations) {
            mutations.forEach(function(mutation) {
                mutation.removedNodes.forEach(function(removedNode) {
                    if(removedNode == spinner) {
                        $(containerNode).removeClass(WRAPPER_NAME);
                        observer.disconnect();
                    }
                });
            })
        })
        
        observer.observe(containerNode, { childList: true });
    }
}(jQuery));
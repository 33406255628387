$(function() {
    var selector = '.card-body > .card';
    
    $(document).on('show.bs.collapse', selector, function() {
        $(this).addClass('show');
    });
    
    $(document).on('hide.bs.collapse', selector, function() {
        $(this).removeClass('show');
    });
})
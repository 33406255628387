var ProcessPage = function() {
    const processBaseUri = contextPath + '/api/{culture}/processes/{processId}';
    const processDataUriTemplate = new URITemplate(processBaseUri);
    const processStepsUriTemplate = new URITemplate(processBaseUri + '/steps');
    const startProcessUriTemplate = new URITemplate(contextPath + '/api/{culture}/processes/start');
    
    const processDataTemplate = Handlebars.compile($('#process-template').html());
    const processStepsTemplate = Handlebars.compile($('#process-steps-template').html());
    const processHierarchyParentsTemplate = Handlebars.compile($('#process-hierarchy-parents-template').html());
    const processHierarchyChildrenTemplate = Handlebars.compile($('#process-hierarchy-children-template').html());
    const fullscreenPdf = Handlebars.compile($('#fullscreen-pdf-partial').html());
    
    const steps = $('#process-steps');
    const stepSelector = new NavigationSelector(steps); 
    
    var currentProcessGraphic;
    var currentProcessDetails;
    
    var currentProcessId;
    
    var pageChangedFromJs;
    
    var isSameProcessView = function(ctx) {
        return currentCulture == ctx.culture && currentProcessId == ctx.processId;
    }
        
    var isProcessStepSelected = function(ctx) {
        return ctx.params.elementId != undefined;
    }
    
    var selectStep = function(ctx) {
        if(isProcessStepSelected(ctx)) {
            stepSelector.select(ctx.elementId);
        }    
    }
    
    var unloadProcessView = function(ctx) {
        currentProcessGraphic = undefined;
        currentProcessDetails = undefined;
        currentProcessId = 0;
    }
    
    this.prepare = function(ctx, next) {
        ctx.culture = ctx.params.culture || currentCulture;
        ctx.languageChange = ctx.culture !=  currentCulture;
        ctx.processId = ctx.params.processId || 0;
        ctx.elementId = ctx.params.elementId || ctx.processId; 
        
        ctx.showStepDetails = ctx.params.showStepDetails != undefined;
        
        if(!isProcessStepSelected(ctx)) {
            hideProcessStepDetails();
        }
        
        if(document.getElementById('process-content') == null || !isSameProcessView(ctx)) {
            unloadProcessView();
        }
        
        hidePrintButton();

        next();
    }
    
    this.openStartPage = function(ctx) {
        disableStepsTab();
        if(pageChangedFromJs) {
            firstLevelOrRedirectToSingleProcess(ctx);    
        }
        else {
            var uri = startProcessUriTemplate.expand({culture: ctx.culture});
            $.getJSON(uri)
                .done(function(startprocess) {
                    if(startprocess.detailsUrl) {
                        page(startprocess.detailsUrl);
                    }
                    else {
                        firstLevelOrRedirectToSingleProcess(ctx);
                    }
                })
        }
    }
    
    var firstLevelOrRedirectToSingleProcess = function(ctx) {
        getProcessStepsJson(ctx)
            .done(function(steps) {
                if(!pageChangedFromJs && steps.subProcesses.length == 1) {
                    page(steps.subProcesses[0].detailsUrl);
                }
                else {
                    $('#process-start').removeClass('d-none');
                    currentProcessId = 0;
                    updateNavigation(ctx, steps);
                }
            });
    }
    
    var getProcessStepsJson = function(ctx) {
        var uri = processStepsUriTemplate.expand({culture: ctx.culture, processId: ctx.processId});
        return $.getJSON(uri);
    }
    
    var loadProcessSteps = function(ctx, anotherPromise) {
        var promise = getProcessStepsJson(ctx)
            .done(function(steps) {
                updateNavigation(ctx, steps);
             });
        
        $.when(promise, anotherPromise).done(function(data) {
            var steps = data[0];
            showProcessSteps(ctx, steps);
        }).fail(function() {
            disableStepsTab();
        });
    }
    
    this.loadProcessData = function(ctx, next) {
        var showProcess = function(processDetails) {
            currentProcessDetails = processDetails;
            
            enableStepsTab();
            
            renderProcessDataTemplate(processDetails);

            next();
        }
        
        if(currentProcessDetails == undefined) {
            var uri = processDataUriTemplate.expand({culture: ctx.culture, processId: ctx.processId});
            
            var promise = $.getJSON(uri)
            .always(function() {
                currentProcessId = ctx.processId;
                currentCulture = ctx.culture;
            })
            .done(function(processDetails) {
                showProcess(processDetails);
            })
            .fail(function(event) {
                unloadProcessView();
                
                disableStepsTab();
            });
            
            loadProcessSteps(ctx, promise);
        }
        else if(document.getElementById('process-content') == null) {
            showProcess(currentProcessDetails);
        }
        else {
            next();
        }
    }
    
    var renderProcessDataTemplate = function(processDetails) {
        document.title = baseTitle + processDetails.title;
        var renderedTemplate = processDataTemplate(processDetails);
        $('#main').html(renderedTemplate);
        hidePrintButton();
    }
    
    
    this.loadProcessStepDetails = function(ctx, next) {
        var stepDetails = $('#process-step-details');
        var stepDetailsUpdater = new SectionUpdater(stepDetails);

        selectStep(ctx);
        
        if(ctx.showStepDetails)
            showProcessStepDetails();
        
        stepDetailsUpdater.load(ctx.canonicalPath)
            .done(function(data) {
                
                stepDetails.html(data);

                showProcessStepsTab();
            }); 
       
        next();
    }
    

    
    function getVisibilityToggle() {
        var stepDetails = $('#process-step-details'); 
        var toggle = stepDetails.find('[data-toggle=visibility][data-target="#process-step-details"]');
        return new VisibilityToggle(stepDetails, toggle);
    }
    
    function showProcessStepDetails() {
        getVisibilityToggle().show();
    }
    
    function hideProcessStepDetails() {
        stepSelector.select(0);
        getVisibilityToggle().hide();
    }
    
    function showPrintButton() {
        $('#print-page-button').show();
    }

    function hidePrintButton() {
        $('#print-page-button').hide();
    }

    this.showProcessGraphic = function(ctx, next) {
        if(currentProcessGraphic == undefined) {
            currentProcessGraphic = new SvgGraphic(
                    currentProcessDetails.svgPath
                    , currentProcessDetails.processSubElements
                    , currentProcessDetails.processId
                    , ctx.elementId);
            
            $('#process-graphic-tab').one('shown.bs.tab', currentProcessGraphic.loadGraphic);
        }
        
        $('#process-graphic-tab').tab('show');
                
        next();
    }
    
    var showProcessSteps = function(ctx, processSteps) {
        if(ctx.elementId > 0) {
            var renderedTemplate = processStepsTemplate(processSteps, {selectedNodeId: ctx.elementId});
            steps.html(renderedTemplate);
            
            var hasFunctions = processSteps.subElements.filter(function(element) {
                return element.type == 'Function';
            }).length > 0;
            
            if(hasFunctions) {
                showProcessStepsTab();
            }
            
            selectStep(ctx);
        }
        else {
            disableStepsTab();
        }
    }
    
    var navigation = new SubNavigation();
    var updateNavigation = function(ctx, processSteps) {
        var renderedTemplateParents = processHierarchyParentsTemplate(processSteps);
        var renderedTemplate = processHierarchyChildrenTemplate(processSteps);
        
        if(ctx.languageChange) {
            $('#sub-navigation-breadcrumb').html(renderedTemplateParents);
            $('#sub-navigation-current-content').html(renderedTemplate);
        }
        else {
            navigation.appendOrPrependWithId(ctx.processId, renderedTemplate, renderedTemplateParents)
        }
    }
    
    this.focusShape = function(ctx) {
        currentProcessGraphic.focusShape(ctx.elementId, true);
    }
    
    this.callCanonicalPath = function(ctx) {
        loadNavigationIfEmpty(ctx);
        
        $.ajax(ctx.canonicalPath)
            .done(function(data) {
                $('#main').html(data);
            });
    }
    
    var loadNavigationIfEmpty = function(ctx) {
        var navigationLoaded = $('#sub-navigation-breadcrumb')
                .add($('#sub-navigation-current-content'))
                .children()
                .length > 0
                
        if(!navigationLoaded) {
            loadProcessSteps(ctx);
        }
    }
    
    var tabAlreadyLoaded = function(contentSelector) {
        return $(contentSelector).children().length > 0
    }
    
    var loadContent = function(url, tabSelector, contentSelector) {
        $(tabSelector).tab('show');
        
        if(!tabAlreadyLoaded(contentSelector)) {
            var content = $(contentSelector);
            var sectionUpdater = new SectionUpdater(content);
            
            sectionUpdater.load(url)
                .done(function(html) {
                    content.html(html);
                });
        }
    } 
    
    this.showProcessDetails = function(ctx) {
        loadContent(ctx.canonicalPath, '#process-details-tab', '#process-details');
        showPrintButton();
    }
    
    this.showControlData = function(ctx) {
        loadContent(ctx.canonicalPath, '#process-control-data-tab', '#process-control-data');
        showPrintButton();
    }

    var showPdf = function(pdfPath, tabSelector, contentSelector) {
        $(tabSelector).tab('show');
        
        if(!tabAlreadyLoaded(contentSelector)) {
            if(pdfPath)
                $(contentSelector).html(fullscreenPdf({pdfPath: pdfPath}));
            else {
                redirectToProcessMainPage();
            }
        }
    }
    
    this.showCockpit = function(ctx, next) {
        showPdf(currentProcessDetails.characteristicsPdfUrl, '#process-cockpit-tab', '#process-cockpit'); 
    }
    
    this.showProcessDescription = function(ctx, next) {
        showPdf(currentProcessDetails.processDescriptionPdfUrl, '#process-description-tab', '#process-description');
    }
    
    this.showMeasurePlan = function(ctx, next) {
        showPdf(currentProcessDetails.measurePlanPdfUrl, '#measureplan-tab', '#measureplan');
    }
    
    var redirectToProcessMainPage = function() {
        if(currentProcessDetails != undefined) {
            page(currentProcessDetails.detailsUrl);
        }
    }
    
    var processHierarchyTab = $('#process-hierarchy-tab');
    var stepsTab = $('#process-steps-tab');
    
    var disableStepsTab = function() {
        stepsTab.stop(true);
        stepsTab.addClass('disabled');
        processHierarchyTab.tab('show');
    }
    
    var enableStepsTab = function() {
        stepsTab.removeClass('disabled');
    }
    
    var showProcessStepsTab = function() {
        enableStepsTab();
        stepsTab.tab('show');
        stepsTab.one('shown.bs.tab', stepSelector.focusActiveNode)
    }
    
    this.pageChangeFromJs = function(ctx, next) {
        pageChangedFromJs = true;
        next();
    }
    
    return {
        prepare: this.prepare,
        openStartPage: this.openStartPage,
        loadProcessData: this.loadProcessData,
        loadProcessStepDetails: this.loadProcessStepDetails,
        showProcessGraphic: this.showProcessGraphic,
        focusShape: this.focusShape,
        callCanonicalPath: this.callCanonicalPath,
        showProcessDetails: this.showProcessDetails,
        showControlData: this.showControlData,
        showCockpit: this.showCockpit,
        showProcessDescription: this.showProcessDescription,
        showMeasurePlan: this.showMeasurePlan,
        pageChangeFromJs: this.pageChangeFromJs
    }
}
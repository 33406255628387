function SectionUpdater(container) {
    var container = $(container);
    
    var mainProgress = new ProgressBar(document.getElementById('main-progress'));
    
    var currentRequest;
    
    function prepare() {
        abortCurrentRequest();
        mainProgress.reset();
        mainProgress.start(); 
        
        container.empty();
        container.loadingSpinner();
    }
    
    function abortCurrentRequest() {
        if(currentRequest) {
            currentRequest.abort();
        }
    }
    
    return {
        load: function(url) {
            prepare();
            
            currentRequest = $.ajax({
                url: url,
                xhr: function() {
                    var xhr = $.ajaxSettings.xhr();
                    
                    xhr.onprogress = function(e) {
                        if (e.lengthComputable) {
                            mainProgress.update(e.loaded, e.total);
                        }
                    };
                   
                    return xhr;
                }
            }).done(function(html) {
                mainProgress.finish();
                
                container.html(html);
                currentRequest = undefined;
            });
            
            return currentRequest;
        }
    }
}

function MainSectionUpdater() {
    var sectionUpdater = new SectionUpdater('#main');

    return {
        load: function(url) {
            sectionUpdater.load(url)
            .done(function(html) {
                document.title =  baseTitle + $('.content-title').text();
            });
        }
    }
}
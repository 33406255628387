Handlebars.registerHelper('concat', function(value1, value2) {
    return value1 + value2;
});

Handlebars.registerHelper('getDisplayName', function(resourceKey) {
    return resourceBundle.get(resourceKey);
});

Handlebars.registerHelper('lengthEquals', function(array, value) {
    return array.length == value;
});

Handlebars.registerHelper('href', function(href) {
    href = href.replace(contextPath, '');
    
    if(href.startsWith('/')) {
        return contextPath + href;
    }
    else {
       return href;
    }
});

Handlebars.registerHelper('iconClass', function(type) {
    return iconClasses.get(type);
});

Handlebars.registerHelper('data', function() {
    var result = '';
    for(var i = 0; i < arguments.length -1; ) {
        var property = arguments[i++];
        var value = arguments[i++];
        
        result += ' data-' + property + '="' + value + '"';
    }

    return result;
});

$(function() {
    $('script[type="text/x-handlebars-template"][data-partial-name]').each(function() {
        var partialName = $(this).data('partialName');
        var template = $(this).html();
        
        Handlebars.registerPartial(partialName, template);
    });
});

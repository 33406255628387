$(window).on('load', function() {
    
    var priorityActionMenu = function() {
        var actionBar = $('.action-bar');
        var headerBar = actionBar.closest('.content-header');
        
        var moreToggle = $('#action-bar-more-toggle');
        var moreContainer = $('#action-bar-more-menu');
        
        var moreToggleIsHidden = moreToggle.is(':hidden');
        
        var calculateTitleWidth = function() {
            var widthUsed = 0;
            var realWidth = 0;
            var freeWidth = headerBar.width() - 8;
            
            headerBar
                .find('.overflow-ellipsis')
                .each(function() {
                    var boundingClientRectWidth = this.getBoundingClientRect().width;
                    widthUsed += Math.ceil(boundingClientRectWidth);
                    
                    realWidth += this.scrollWidth;
                });
            
            headerBar
                .children()
                .each(function() {
                    freeWidth -= $(this).outerWidth(false);
                });
            
            var widthNeeded = (realWidth - widthUsed);
            if(freeWidth < 0) {
                widthNeeded -= freeWidth;
            }
            
            return {
                freeWidth: Math.floor(freeWidth),
                widthNeeded: widthNeeded
            }
        }
        
        var hideAsMuchActionButtonsAsNeeded = function(widthNeeded) {
            if(moreToggleIsHidden) {
                moreToggle.css({ position: "absolute", visibility: "hidden", display: "block !important" });
                moreToggle.removeClass('d-none');
                widthNeeded += moreToggle.width();
                moreToggle.addClass('d-none');
                moreToggle.css({ position: "", visibility: "", display: "" });
            }
            var minNumOfElementsToMove = moreToggleIsHidden ? 2 : 1;
            
            var elements = actionBar.children(':visible:not([data-never-hide])'); 
            
            if(elements.length < minNumOfElementsToMove) {
                return;
            }
            
            var numOfElementsToMove;
            var elementsToMove = [];

            for(var i = 0; 
                i < elements.length 
                    && widthNeeded > 0 
                    || elementsToMove.length < minNumOfElementsToMove; 
                i++
            ) {
                var element = elements[i];
                var width = $(element).outerWidth(true);
                
                $(element).data('width', width);
                elementsToMove.push(element);
                
                widthNeeded -= width;
            }
            
            $(elementsToMove).addClass("dropdown-item");
            $(elementsToMove).appendTo(moreContainer);
            
            moreToggle.removeClass('d-none');
        }
        
        var showAsMuchActionButtonsAsPossible = function() {
            
            var moreElements = moreContainer.children();
            if(moreElements.length == 0) {
                return;
            }
            
            var titleWidth = calculateTitleWidth();
            var freeSpace = titleWidth.freeWidth;

            var elementsToMove = [];

            for(var i = moreElements.length - 1;
                i > 0 && freeSpace > 0;
                i--
            ) {
                var lastElement = moreElements[i];
                var width = $(lastElement).data('width');
            
                if(freeSpace >= width) {
                    elementsToMove.unshift(lastElement);
                }
                freeSpace -= width;
            }
            
            if(elementsToMove.length == 0) {
                return;
            }
            
            if(moreElements.length - elementsToMove.length == 1) {
                var lastElement = moreElements[0];
                var width = $(lastElement).data('width');
                
                freeSpace += moreToggle.outerWidth(true);
                
                var enoughSpaceToSwitchToggle = freeSpace > width + 8;
                
                if(enoughSpaceToSwitchToggle) {
                    elementsToMove.unshift(moreElements[0]);
                }
                else {
                    elementsToMove.shift();
                }
            }
            
            var firstElement = actionBar.children('[data-never-hide]').first();
            
            $(elementsToMove).insertAfter(firstElement)
            $(elementsToMove).removeClass('dropdown-item');
            
            moreToggle.toggleClass('d-none', moreContainer.children().length == 0);
        }
        
        if(headerBar.width() > headerBar.data('width')) {
            showAsMuchActionButtonsAsPossible();
        }
        else {
            var widthNeeded = calculateTitleWidth().widthNeeded;
            if((widthNeeded) > 0) {
                hideAsMuchActionButtonsAsNeeded(widthNeeded);
            }
        }
        
        headerBar.data('width', headerBar.width());
    };
    
    priorityActionMenu();
    
    $('#main').on('resized', priorityActionMenu);
    
    const observer = new MainContainerMutationObserver(priorityActionMenu);
    observer.observe({childList: true,});

})
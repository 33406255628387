$(function() {
  $('[data-check-option=checked] input[type=checkbox]').on('change', function() {
      if ($(this).is(':checked') == false) {
          $(this).siblings('.custom-checkbox')
              .find('input[type=checkbox]')
              .prop('checked', false);
      }
      else {
          $(this).parents('.custom-checkbox')
              .not($(this).closest('.custom-checkbox'))
              .children('input[type=checkbox]')
              .prop('checked', true);
      }
  });
  
  $('[data-check-option=indeterminate] > input[type=checkbox]').on('change', checkAllChildren);
  function checkAllChildren() {
    var checked = $(this).is(':checked')
    
    var children = $(this).siblings('.custom-checkbox')
        .find('input[type=checkbox]');
    children.prop('checked', checked);
  }
  
  
  var indeterminatableChildren = $('[data-check-option=indeterminate] > .custom-checkbox input[type=checkbox]');
  indeterminatableChildren.on('change', determineIndeterminatableCheckBoxState);
  
  indeterminatableChildren.each(determineIndeterminatableCheckBoxState);
  
  function determineIndeterminatableCheckBoxState() {
    var parent = $(this).parents('.custom-checkbox')
        .not($(this).closest('.custom-checkbox'))
        .children('input[type=checkbox]');
    
    if(parent.length > 0) {
        var checkboxes = $(this)
            .parent('.custom-checkbox')
            .siblings('.custom-checkbox')
            .addBack()
            .children('input[type=checkbox]');
        
        var checkedCheckboxes = checkboxes.filter(':checked');
        
        parent.prop('checked', checkedCheckboxes.length > 0);
        parent.prop('indeterminate',  checkedCheckboxes.length > 0 && checkboxes.length > checkedCheckboxes.length);
    }
}
});
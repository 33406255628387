(function ($) {
    "use strict";

    $.fn.sortList = function (direction) {
        $(this).each(function() {
            
            var $list = $(this);
            var $listLi = $('li',$list);
            $listLi.sort(function(a, b){
                var keyA = $(a).text().trim().toUpperCase();
                var keyB = $(b).text().trim().toUpperCase();
                
                if(keyA > keyB) {
                    return 1;
                }
                else if(keyA < keyB) {
                    return -1;
                }
                
                return 0;
            });
            $.each($listLi, function(index, row){
                $list.append(row);
            });
        })
    };
}(jQuery));
$(function() {
    
    const visibleClass = 'show';
    const collapsedClass = 'collapsed';
    
    var sidebar = $('#sidebar');
    var sidebarToggle = $('#sidebar-toggle');

    var visibilityToggle = new VisibilityToggle(sidebar, sidebarToggle);
    
    function hideSidePanelOnSmallScreen() {
        if(screenSizeDetector.isEqualOrSmallerThan('md')) {
            visibilityToggle.hide();
            return true;
        }
        else {
            return false;
        }
    }
    
    function hideOrShowSidePanelDependingOnScreenSize() {
        if(!hideSidePanelOnSmallScreen()) {
            visibilityToggle.show();
        }
    }
    
    function resizeTrigger() {
        $('#main').trigger('resized');
    }
    
    sidebar.on('syc.visibility.shown syc.visibility.hidden syc.visibility.transitioning', resizeTrigger);
    
    sidebar.addClass('suppress-transition');
    hideOrShowSidePanelDependingOnScreenSize();
    sidebar.removeClass('init');
    
    $(window).on('resize', hideSidePanelOnSmallScreen);
    
    
    sidebarToggle.on('click', visibilityToggle.toggle);
    
    setTimeout(function() {
        sidebar.removeClass('suppress-transition');
    }, 10) //workaround
    
    $(document).on('click', '#main', function() {
        if(screenSizeDetector.isEqualOrSmallerThan('md')) {
            visibilityToggle.hide();
        };
    })
});
    
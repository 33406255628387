$(function() {
    (function DocumentTypeOrdering() {
        var $this = this;
        var documentTypeList = $('#documenttype-order');
        var customOrderSwitch = $('#custom-order-switch');
        var resetAllSwitch = $('#custom-order-reset-all');
        
        var customOrdered = false;
        
        (function init() {
            customOrdered = customOrderSwitch.prop('checked');
            
            documentTypeList.sortable({
                ghostClass: 'active',
                chosenClass: 'active',
                onUpdate: updateOrder,
                delayOnTouchOnly: true,
                delay: 200
            });
            
            customOrderSwitch.on('change', function() {
                if(this.checked) {
                    enableListControls();
                    sendUpdateOrder()
                }
                else {
                    resetOrder();
                }
            });
            toggleListControls();
            
            resetAllSwitch.on('click', resetAll);
        })();
        
        function toggleListControls() {
            if(customOrderSwitch.prop('checked')) {
                enableListControls();
            } 
            else {
                disableListControls();
            }
        }
        
        function updateOrder(e) {
            customOrdered = true;
            documentTypeList.sortable('disabled', true);
            $(e.item).loadingSpinner('sm');
            
            sendUpdateOrder()
                .done(function() {
                    documentTypeList.sortable('disabled', false);
                    $(e.item).loadingSpinner('destroy');
                });
        }
        
        function sendUpdateOrder() {
            var documentTypeId = documentTypeList.data('documentTypeId');
            var newOrder = [];
            
            documentTypeList.children().each(function() {
                newOrder.push($(this).data('id')); 
            });
            
            var update = {
              documentTypeId: documentTypeId,
              newOrder: newOrder
            }

            return $.ajax({
                url: contextPath + '/preferences/admin/documenttype-order',
                type: 'POST',
                contentType: 'application/json',
                data: JSON.stringify(update)
            });
        }
        
        function resetOrder() {
            function executeReset() {
                documentTypeList.sortList();
                disableListControls();
                var documentTypeId = documentTypeList.data('documentTypeId');

                $.ajax({
                    url: contextPath + '/preferences/admin/documenttype-order/reset/' + documentTypeId,
                    type: 'POST'
                });
                
                customOrdered = false;
            }
            
            if(customOrdered) {
                new ConfirmationDialog({
                    body: customOrderSwitch.data('confirmationBody'),
                    onConfirmation: executeReset,
                    onCancel: function() {
                        customOrderSwitch.prop('checked', true);
                    }
                }).openDialog();
            }
            else {
                executeReset();
            }
        }
        
        function enableListControls() {
            documentTypeList.children()
                .children()
                .addClass('mdi mdi-unfold-more-horizontal')
            documentTypeList.sortable('disabled', false);
        }
        
        function disableListControls() {
            documentTypeList
                .children()
                .children()
                .removeClass('mdi-unfold-more-horizontal')
            documentTypeList.sortable('disabled', true);
        }
        
        function resetAll() {
            new ConfirmationDialog({
                body: resetAllSwitch.data('confirmationBody'),
                onConfirmation: function() {
                    $.ajax({
                        url: contextPath + '/preferences/admin/documenttype-order/resetall',
                        type: 'POST'
                    })
                    .done(function() {
                        window.location.href = contextPath + '/preferences/admin/documenttype-order';
                    });
                }
            }).openDialog();
        }
    })();
})
$(function() {
    var isUnloading = false;

    $(document).ajaxComplete(function(_event, xhr) {
        var sessionId = xhr.getResponseHeader('X-Session-Id');
        
        if(sessionId && currentSessionId != sessionId) {
            window.location.reload();
        }
    });
    
    $(document).ajaxSuccess(function(_event, _xhr, _settings, data) {
        if(data && data.isAjaxRedirect) {
            pageOfTabRememberer.removeLastPage();
            
            linkOpener.open(data.redirectToUri);
        }
    });
    
    $(document).ajaxError(function(_event, xhr, settings) {
        if(isUnloading || xhr.statusText == "abort") {
            return;
        }
        
        if (settings.suppressErrors || isProcessGraphicFile(settings.url)) {
            return;
        }
        
        var contentType = xhr.getResponseHeader("content-type") || '';
        if (xhr.status == 401) {
            window.location.reload();
        }
        else if(xhr.status >= 400 && contentType.indexOf('html') > -1) {
            $('#main').html(xhr.responseText);
            return;
        }
        else if (xhr.status == 403) {
            try {
                var json = JSON.parse(xhr.responseText);
                if(json.cause == 'not-logged-in') {
                    window.location.reload();
                }
            }
            catch(e) {
                console.warn(e);
            }
        }
        else {
            var message = resourceBundle.get('de.cim_house.sycat.dokweb.configuration.error.nosuccess');
            notificationBox.appendMessage('danger', message); 
        }
    });
    
    function isProcessGraphicFile(url) {
        return url.endsWith('/process.svg');
    }

    $(window).on('beforeunload', () => {
        isUnloading = true;
    })
})
$(function() {
    $(document).on('click', '[data-action=copy-to-clipboard]', function(event) {
        event.preventDefault();
        
        var text = $(this).text();
        
        var href= $(this).attr('href');
        
        tmpElementForCopy = document.createElement('input');
        tmpElementForCopy.style.height = 1;
        tmpElementForCopy.style.width = 1;
        
        document.body.appendChild(tmpElementForCopy);
        
        tmpElementForCopy.value = href;
        
        tmpElementForCopy.select()

        document.execCommand("copy");
        
        var message = resourceBundle.get('de.cim_house.sycat.dokweb.common.copied.to.clipboard', href);
        notificationBox.appendMessage('success', message);  

        document.body.removeChild(tmpElementForCopy);
    })
})
function OrganizationPage() {
    const navigationUriTemplate = new URITemplate(contextPath + '/org/{culture}/{orgUnitId}/navigation');
    
    var mainSectionUpdater = new MainSectionUpdater();
    var navigation = new SubNavigation();
    
    var prepare = function(ctx, next) {
        ctx.culture = ctx.params.culture || currentCulture;
        ctx.orgUnitId = ctx.params.orgUnitId || 0;
        
        next();
    }
    
    var shouldSkipNavigationUpdate = function(id) {
        var selectedEntry = $('#sub-navigation-current-content')
            .find('li[data-id=' + id + ']').first();
        
        if(!selectedEntry.length) {
            return false;
        } 
        
        return !($(selectedEntry).data('expandable') == true);
    }
    
    var updateNavigation = function(ctx, next) {
        if(shouldSkipNavigationUpdate(ctx.orgUnitId)) {
            navigation.select(ctx.orgUnitId);
            return next();
        }
        
        $.ajax({
            url: navigationUriTemplate.expand({culture: ctx.culture, orgUnitId: ctx.orgUnitId})
        }).done(function(html) {
            
            var content = $($(html).filter('.sidebar-content').html());
            
            
            var children = content.filter('#sub-navigation-current-content').html();
            var parents = content.filter('#sub-navigation-breadcrumb').html();
            
            navigation.appendOrPrependWithId(ctx.orgUnitId, children, parents);
        })
        
        next();
    }
    
    var updateDetails = function(ctx, next) {
        const detailsUriTemplate = new URITemplate(contextPath + '/org/{culture}/{orgUnitId}/details');
        
        var url = detailsUriTemplate.expand({culture: ctx.params.culture, orgUnitId: ctx.params.orgUnitId});
        mainSectionUpdater.load(url);
    };
    
    var welcome = function(ctx, next) {
        mainSectionUpdater.load(contextPath + '/org/');
    }
    
    return {
        prepare: prepare,
        updateNavigation: updateNavigation,
        updateDetails: updateDetails,
        welcome: welcome 
    }
}
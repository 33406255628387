function Draggable(draggableElement, options) {
    var draggableElement = $(draggableElement);
    var handle;
    var container;
    
    (function loadOptions() {
        if(draggableElement.data('drag-handle') == undefined) {
            handle = draggableElement;
        }
        else {
            handle = $(draggableElement.data('drag-handle'));
        }
        
        container = options.container ? $(options.container) : $('body'); 
    })();
    
    handle.on('mousedown touchstart', dragMove)
    
    function dragMove(event) {
        
        var element = $(draggableElement);

        var offsetX, offsetY, minX, maxX, minX, maxX;
        
        (function start() {
            $('body').disableSelection();
            
            calculateOffsets();
            calculateBoundaries();
            
            element.addClass('dragging');
        })();
        
        function calculateOffsets() {
            var handleOffset = handle.offset();
            var containerOffset = container.offset();
            
            offsetX = pageX(event) - (handleOffset.left - containerOffset.left - parseInt(element.css('marginLeft')));
            offsetY = pageY(event) - (handleOffset.top - containerOffset.top - parseInt(element.css('marginTop')));
        }
        
        function pageX(event) {
            return Math.round(event.pageX != undefined ? event.pageX : event.touches[0].pageX);
        }
        
        function pageY(event) {
            return Math.round(event.pageY != undefined ? event.pageY : event.touches[0].pageY);
        } 
        
        function calculateBoundaries() {
            minX = 0;
            minY = 0;
            
            maxX = container.width() - element.outerWidth(true);
            maxY = container.height() - element.outerHeight(true);
            
            maxX = Math.round(maxX);
            maxY = Math.round(maxY);
        }
        
        function move(event) {
            var x = pageX(event) - offsetX;
            var y = pageY(event) - offsetY;
            
            moveTo(x, y);
        }
        
        function moveTo(x, y) {
            var coords = boundarySafeCoordinates(x, y);
            
            $(element).css('left', coords.x)
            $(element).css('top', coords.y)
            
            if(coords.x == minX) 
                element.snapLeft();
            else if(coords.x == maxX) 
                element.snapRight();
            else 
                element.unsnapX();
            
            if(coords.y == minY) 
                element.snapTop();
            else if(coords.y == maxY) 
                element.snapBottom();
            else
                element.unsnapY();
        }
        
        function boundarySafeCoordinates(x, y) {
            x = Math.min(x, maxX);
            y = Math.min(y, maxY);

            x = Math.max(x, minX);
            y = Math.max(y, minY);
            
            return {
                x: Math.round(x),
                y: Math.round(y)
            }
        }   
        
        function end() {
            $('body').enableSelection();
            element.removeClass('dragging');
        }
        
        function keepInBoundaries() {
            var position = element.position()
            calculateBoundaries();
            
            moveTo(position.left, position.top);
        }
        
        $(window).on('mousemove.drag touchmove.drag', move);
        
        $(window).one('mouseup touchend', function() {
            end();
            $(window).off('mousemove.drag touchmove.drag');
        })
        
        $('#main').on('resized', keepInBoundaries);
    }
}





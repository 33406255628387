function LinkOpener() {
    return {
        open: function (href) {
            var pageBase = page.base();
            
            if(href.startsWith(location.origin)) {
                href = href.slice(location.origin.length);
            }
            
            if(href.startsWith(pageBase)) {
                href = href.slice(pageBase.length);
                page(href);
            }
            else {
                window.location.href = href;
            }
        }
    }
}

linkOpener = new LinkOpener();
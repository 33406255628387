$(function () {
    $(document).popover({
      selector: '[data-toggle="popover"]',
      trigger: 'focus',
      html: true,
      content: function() {
        var content = $(this).data('content');
        if(content)
            return content;
        
        var target = $(this).data('target');
        
        return $(target).html();
      }
    });
    
    $(window).on('pagechange', function() {
        $('.popover').popover('hide');
    })
});
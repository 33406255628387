
//confirm withdraw dialog
$(function() {
    
    $(document).on('click.withdraw', 'a[data-control-type="NON_CONTROLLED"]', function(e) {
        e.preventDefault();
        
        var withdrawDialog = $('#withdraw-confirmation');
        var button = $(e.target).closest('a, button');

        withdrawDialog.find('#documentShortName').text(button.data('documentShortname'));
        withdrawDialog.find('#documentLongName').text(button.data('documentName'));
        
        var withdrawUrl = button.attr('href');
        
        withdrawDialog.modal('show');
        
        var confirmationButton = $('#withdraw-confirm', withdrawDialog);
        
        confirmationButton.one('click.confirm-withdraw', function(e) {
            withdrawDialog.modal('hide');

            var $this = $(this);

            $.ajax({
                type : "DELETE",
                url : withdrawUrl,
                suppressErrors : true,
            }).done(function(data) {
                var documentRoot = contextPath + '/document/';
                
                if(window.location.pathname.startsWith(documentRoot)) {
                    linkOpener.open(documentRoot);    
                }
                
                notificationBox.appendMessage('success', $this.data('success-message'));
            }).fail(function(data) {
                notificationBox.appendMessage('danger', $this.data('fail-message'));
            });
        })
        
        withdrawDialog.one('hide.bs.modal', function() {
            confirmationButton.off('click.confirm-withdraw');
        })
    });
})

//read confirmation dialog
$(function() {
    var readConfirmationDialog = $('#read-confirmation-dialog');
    var notReadDialog = $('#read-confirmation-not-read');

    var ReadConfirmation = function(dom) {
        var documentId = $(dom).data('document-id');
        var title = $(dom).data('document-title');
        var documentState = $(dom).data('document-state');
        var isProcessDocument = $(dom).data('document-process');

        var downloadUrl = $(dom).data('download-url');
        var downloadTarget = $(dom).data('target') || '_blank';

        completable = $(dom).data('completable') || documentState.toUpperCase() == 'READ';

        this.openDialog = function() {
            if (this.isCompletable()) {
                this.openConfirmationDialog();
            } else {
                this.showNotCompletableMessage();
            }
        }

        this.isCompletable = function() {
            if (!completable) {
                $.ajax({
                    url : contextPath + '/readconfirmation/iscompletable/' + documentId,
                    method : 'GET',
                    dataType : 'json',
                    async : false
                }).done(function(data) {
                    $(dom).data('completable', data.completable);
                    completable = data.completable;
                });
            }
            return completable;
        }

        var postCompletion = function() {
            var confirmation = {
                    documentId : documentId
            }

            return $.ajax({
                url : contextPath + '/readconfirmation/',
                method : 'POST',
                data : JSON.stringify(confirmation),
                contentType : 'application/json',
                suppressErrors : true
            })
        }

        this.openConfirmationDialog = function() {
            toggleDocumentAndProcessContextVisibility(readConfirmationDialog);

            readConfirmationDialog
            .find('#readconfirmation-document-name')
            .text(title);

            var confirmButton = readConfirmationDialog
            .find('#readconfirmation-confirm-button')

            confirmButton.off('click.read-confirmation');
            confirmButton.on('click.read-confirmation', function() {
                postCompletion()
                .done(function() {
                    $(dom)
                    .parent('li')
                    .addBack()
                    .remove();

                    var message = confirmButton
                    .data('success-message')
                    .replace('{0}', title);

                    notificationBox.appendMessage('success', message);
                })
                .fail(function() {
                    var message = confirmButton
                    .data('failed-message')
                    .replace('{0}', title);

                    notificationBox.appendMessage('danger', message);
                })
                .always(function() {
                    readConfirmationDialog.modal('hide');
                });
            });

            readConfirmationDialog.modal('show');
        }

        var toggleDocumentAndProcessContextVisibility = function(dialog) {
            dialog
            .find('.context-document')
            .toggle(!isProcessDocument);

            dialog
            .find('.context-process')
            .toggle(isProcessDocument);
        }

        this.showNotCompletableMessage = function() {
            toggleDocumentAndProcessContextVisibility(notReadDialog);

            notReadDialog
            .find('#readconfirmation-open-document')
            .attr('href', downloadUrl)
            .attr('target', downloadTarget);

            notReadDialog.modal('show');
        }
    }

    $(document).on('click', '#readconfirmation-open-document', function(e) {
        notReadDialog.modal('hide');
    })

    $(document).on('click', ':not(.disabled) .readconfirmationbutton', function(e) {
        var confirmation = new ReadConfirmation(this);

        confirmation.openDialog();
    });
    
    
});


function ConfirmationDialog(options) {
    var dialog = $('#confirmation-dialog').clone();
    var confirmed = false;
    
    (function bindConfirmationCallback() {
        if(typeof options.onConfirmation === 'function') {
            dialog.find('#confirmation-dialog-confirm').on('click', function() {
                confirmed = true;
                dialog.modal('hide');
                options.onConfirmation();
            });
        }
    })();
    
    (function bindEvents() {
        dialog.on('hide.bs.modal', function() {
            if(!confirmed && typeof options.onCancel === 'function') {
                options.onCancel();
            }
        })
        
        dialog.on('hidden.bs.modal', function() {
            dialog.remove();
        })
    })();

    (function defineDalogTexts() {
        dialog.find('.modal-title').html(options.title);
        dialog.find('.modal-body').html(options.body);
    })();
    
    
    return {
        openDialog: function() {
            dialog.modal('show');
        }
    }
}

$(function() {
    function placement () {
        return $(this.element).data('placement') || 'bottom';
    }
    
    $('body').on('mouseenter', '.overflow-ellipsis', function() {
        if(this.scrollWidth > this.offsetWidth) {
            $(this).tooltip({
                title: $(this).text(),
                container: 'body',
                boundary: 'window',
                placement: placement
            });
            $(this).tooltip('show');
        }
        else {
            $(this).tooltip('dispose');
        }
    });
    
    $('body').tooltip({
        selector: '[data-toggle=tooltip], [title]',
        container: 'body',
        boundary: 'window',
        placement: placement,
        trigger: 'hover',
        title: function() {
            if($(this).hasClass('icon-link')) {
                var caption = $(this).children('.link-caption');
                if(caption.is(':hidden')) {
                    return caption.text();
                }
            }
            else {
                return $(this).data('title');
            }
        }
    });
    
    $(document).on('click', function() {
        $('.tooltip').tooltip('hide');
    })
    
    $(window).on('pagechange', function() {
        $('.tooltip').tooltip('hide');
    });
});
function VisibilityToggle(targetElement, toggleElement) {
    const visibleClass = 'show';
    const collapsedClass = 'collapsed';
    
    targetElement = $(targetElement);
    toggleElement = $(toggleElement);
    
    var hideOnLostFocus = targetElement.data('hide-on-lost-focus') == true;
    
    function show() {
        if(!targetElement.hasClass(visibleClass)) {
            triggerEvents('syc.visibility.show', 'syc.visibility.shown');
            targetElement.addClass(visibleClass);
            
            toggleElement.removeClass(collapsedClass);
            toggleElement.attr('aria-expanded', true);
            
            bindLostFocusEvents();
        }
    };
    
    function hide() {
        if(targetElement.hasClass(visibleClass)) {
            triggerEvents('syc.visibility.hide', 'syc.visibility.hidden');
            
            targetElement.removeClass(visibleClass);
            
            toggleElement.addClass(collapsedClass);
            toggleElement.attr('aria-expanded', false);
        }
    }
    
    function toggle() {
        if(targetElement.hasClass(visibleClass)) {
            hide();
        }
        else {
            show();
        }
    }
    
    function bindLostFocusEvents() {
        var lostEvent = function(event) { 
            if(event.target == window || !targetElement[0].contains(event.target)) {
                $(document).off('click.visibility.lost.focus', lostEvent);
                $(window).off('blur.visibility.lost.focus pagechange', lostEvent);
                
                hide();
            }
        };
        
        if(hideOnLostFocus) {
            $(document).on('click.visibility.lost.focus', lostEvent);
            $(window).on('blur.visibility.lost.focus', lostEvent);
        }
    }
        
    function triggerEvents(startEvent, endEvent) {
        targetElement.trigger(startEvent);
        
        var interval = setInterval(function() {
            targetElement.trigger('syc.visibility.transitioning');
        }, 25);
        
        targetElement.one('transitionend', function() {
            targetElement.trigger(endEvent);
            clearInterval(interval);
        })
    }
    
    return {
        show: show, 
        hide: hide,
        toggle: toggle
    }
}

$(function() {
    $(document).on('click', '[data-toggle=visibility]', function(event) {
        var target = $(this).data('target');
        if(!target)
            return;
        
        event.preventDefault();
        new VisibilityToggle(target, this).toggle();
    })
})
(function ($) {
    const defaultConfig = {
            delay: 200,
            minLength: 3,

            data: {
                remote: {
                    ajax: {
                        url: '',
                        method: 'GET'
                    }
                },

            },

            template: '#search-result-template'
    }

    $.fn.autocomplete = function(config) {
        if(this.length == 0) 
            return;
        
        $(this).each(function() {
          config = $.extend(true, {}, defaultConfig, config);
  
          const $queryField = $(this).find(config.queryField);
          const $resultContainer = $(this).find(config.resultContainer);
          const $dropdownMenu = $resultContainer.children('.dropdown-menu');
          const $dropdownToggle = $resultContainer.children('a');
          
          const template = Handlebars.compile($(config.template).html());
  
          const startSuggestion = function() {
              const query = this.value || '';
              if(query.length >= config.minLength) {
                  receiveSuggestionData(query)
                  .done(function(data) {
                      resetSuggestion();
                      processResults(data, query);
                  });
              }
              else {
                  resetSuggestion();
                  close();
              }     
          }
  
          const receiveSuggestionData = function(query) {
              query = encodeURIComponent(query);
              
              const remoteConfig = config.data.remote; 
              const ajax = remoteConfig.ajax;
              const url = ajax.url.replace('{{query}}', query);
              return $.ajax({
                  url: url,
                  method: ajax.method
              })
          }
  
          const resetSuggestion = function() {
              $dropdownMenu.empty();
          }
  
          const processResults = function(data, query) {
              data.query = data.query || query;
              const defaultData = config.data.defaults
  
              data = $.extend(true, {}, defaultData, data);
  
              if(config.data.filter) {
                  data = config.data.filter(data);
              }
              
              const html = template(data);
              $dropdownMenu.append(html);
  
              open();
          }
          
          const open = function() {
              if(!isOpen()) {
                  toggle();
              }
          }
          const isOpen = function() {
              return $resultContainer.hasClass('show');
          }
          
          const close = function() {
              if(isOpen()) {
                  toggle();
              }
          }
  
          const toggle = function(){
              $dropdownToggle.dropdown('toggle');
              if (!$queryField.is(":focus")) {
                  $queryField.focus();
              }
          }
                  
          $queryField.on('input', debounce(startSuggestion, config.delay));
          
          $queryField.on('focus', function(event) {
              if($dropdownMenu.find('li').length > 0) {
                  open();
              }
          });
          
          $queryField.on('keydown', function(event) {
              if(event.which == 40) { //arrow down
                  event.preventDefault();
  
                  if($resultContainer.hasClass('show')) {
                      $resultContainer
                      .find('.dropdown-menu > li > a')
                      .first()
                      .focus();
                  }
  
                  event.stopPropagation();
              }
              else if(event.which == 27) { //ESC
                  close();
              }
          });
         
          $resultContainer.on('keydown.bs.dropdown.data-api', function(event) {
              const $items = $resultContainer.find('li:not(.disabled):visible a');
              const index = $items.index(event.target);
  
              if(event.which == 38 && index == 0) {
                  event.preventDefault();
                  $queryField.focus();
                  const charPosition = $queryField.val().length;
                  $queryField[0].setSelectionRange(charPosition, charPosition);
                  event.stopPropagation();
              }
          });
          
          function keepResultOpenIfInputIsFocused() {
              let inputHasFocus = false;
              $queryField.on('focus', function(event) {
                  inputHasFocus = true;
              });
              
              $queryField.on('focusout', function(event) {
                  inputHasFocus = false;
              });
              
              $resultContainer.on('hide.bs.dropdown', function(event) {
                  if(inputHasFocus && $queryField.val().length >= config.minLength) {
                      $queryField.focus();
                      return false;
                  }
              });
          }
          keepResultOpenIfInputIsFocused();
        })
    };
}(jQuery));
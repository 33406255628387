var screenSizeDetector = (function() {
    var viewPorts = ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'];

    var viewPortSize = function() {
        return window.getComputedStyle(document.body, ':before').content.replace(/"/g, '');
    }

    var is = function(size) {
        validate(size);
        return viewPortSize() == size;
    }

    var isEqualOrGreaterThan = function(size) {
        validate(size);
        return viewPorts.indexOf(viewPortSize()) >= viewPorts.indexOf(size);
    }
    
    var isEqualOrSmallerThan = function(size) {
        validate(size);
        return viewPorts.indexOf(viewPortSize()) <= viewPorts.indexOf(size);
    }
    
    var validate = function(size) {
        if ( viewPorts.indexOf(size) == -1 ) throw "no valid size given";
    }
    
    var isEmbeddedInIframe = function() {
        try {
            return window.self !== window.top;
        } catch (e) {
            return true;
        }    
    
    }
    // Public API
    return {
        is: is,
        isEqualOrGreaterThan: isEqualOrGreaterThan,
        isEqualOrSmallerThan: isEqualOrSmallerThan,
        isEmbeddedInIframe: isEmbeddedInIframe
    }

})();
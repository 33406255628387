function SvgGraphic(svgPath, processElements, processObjectId, selectedElementId) {
    var animationDuration = 150;

    var _graphic = this;
    this.processContainer = $('#processContainer');
    
    this.processContainerElement = this.processContainer[0];
    this.panAndZoomSvgContainer = $('#panandzoom');
    
    this.panAndZoom;
 
    var panAndZoomContainer = $('#panandzoom-container'); 
    
    var viewBox;
    var viewPort;
    var slider;

    var svgContainer;
    var svgGraphic;
    var svgElement;
    var svgMainGroup;

    var svgDimensions;

    var isGesticulating = false;
    
    this.initSVG = function(svg, status) {
        if(status == 'error') {
            return;
        }
        
        svgGraphic = svgContainer.children('svg');

        svgElement = svgGraphic.get(0);

        svgDimensions = new SvgDimensions(svgElement);

        viewBox = new ViewBox();

        svgMainGroup = $(svgGraphic).children('g');

        _graphic.overrideShapeAttributes();

        _graphic.loadZoomWidgets();

        _graphic.loadPanAndZoom();

        viewBox.update();

        $(_graphic.processContainer).trigger('onload');
        
        $(_graphic.processContainer).on('touchmove', function(e) {
            e.preventDefault();
        })

        new Bzzzz();
        
        _graphic.focusShape(selectedElementId, false);
    }

    var SvgDimensions = function(svg) {
        var width =  		svg.width.baseVal.value;
        var height = 		svg.height.baseVal.value;
        var ratio =  		svg.height.baseVal.value / svg.width.baseVal.value;
        var originWidth = 	svg.viewBox.baseVal.width;
        var originHeight = 	svg.viewBox.baseVal.height;
        var originRatio =	svg.width.baseVal.value / svg.viewBox.baseVal.width;

        this.width = function() {
            return width;
        }

        this.height = function() {
            return height;
        }
        this.ratio = function() {
            return ratio;
        }
        this.originWidth = function() {
            return originWidth;
        }
        this.originHeight = function() {
            return originHeight;
        }
        this.originRatio = function() {
            return originRatio;
        }
    }


    var ViewBox = function() {
        var $this = this;

        this.width;
        this.height;

        this.minScale;
        this.maxScale = 3;

        var events = [];
        
        var triggerEvents = function() {
            for(var i = 0; i < events.length; i++) {
                events[i]();
            }
        }

        this.addUpdateEventListener = function(event) {
            events.push(event);
        }

        this.update = function() {
            $this.width = svgContainer.width();
            $this.height = svgContainer.height();

            svgElement.setAttribute('viewBox', ' 0 0 ' + $this.width + ' ' + $this.height);
            
            svgElement.setAttribute('width', $this.width);
            svgElement.setAttribute('height', $this.height);
            
            var ratioX = svgDimensions.width() / $this.width;
            var ratioY = svgDimensions.height() / $this.height;

            var ratio = Math.max(ratioX, ratioY);

            $this.minScale = 1 / ratio * svgDimensions.originRatio();

            triggerEvents();
        }
        
        this.update();

        $('#main').on('resized', this.update);
        $('#process-graphic-tab').on('shown.bs.tab', this.update);
        $('#process-content-wrapper').on('transitionend', this.update);
        
        updateWhileTransitioning('#process-step-details');
        
        function updateWhileTransitioning(element) {
            element = $(element);

            function trackShape() {
                $this.update();
                _graphic.focusShape(selectedElementId, false);
            }
            
            element.on('syc.visibility.transitioning', trackShape);
        }
    }

    var GraphicSize = function(scale) {
        this.width = svgDimensions.originWidth() * scale;
        this.height = svgDimensions.originHeight() * scale;

        this.centerX = (viewBox.width - this.width) / (2 * scale);
        this.centerY = (viewBox.height - this.height) / (2 * scale);

        this.maxX = Math.max(0, this.centerX * -1);
        this.maxY = Math.max(0, this.centerY * -1);
    }

    GraphicSize.prototype.fitX = function(x) {
        return Math.min(Math.abs(x), this.maxX) * (x < 0 ? -1 : 1);
    }

    GraphicSize.prototype.fitY = function(y) {
        return Math.min(Math.abs(y), this.maxY) * (y < 0 ? -1 : 1);
    }

    GraphicSize.prototype.toTranslateX = function(x) {
        return this.centerX + x;
    }

    GraphicSize.prototype.toTranslateY= function(y) {
        return this.centerY + y;
    }


    var ViewPort = function() {
        var $this = this;

        var x = 0;
        var y = 0;

        var graphicSize;

        var scale = 0;

        var isFullSize = true;

        var events = [];

        this.getX = function() {
            return x;
        }

        this.getY = function() {
            return y;
        }

        this.getScale = function() {
            return scale;
        }

        this.move = function(toX, toY, duration) {
            this.transform(toX, toY, scale)
        }

        var svgTransform = function(animation, progress) {
            graphicSize = new GraphicSize(this.scale);
            x = this.x;
            y = this.y;
            scale = this.scale;

            isFullSize = scale == viewBox.minScale;

            var scaleParam = ' scale(' + this.scale  + ') ';
            var translateParam = ' translate(' + graphicSize.toTranslateX(x) + ', ' +  graphicSize.toTranslateY(y) + ') ';	
            var transform =  scaleParam + translateParam;
            svgMainGroup.attr('transform', transform);
            triggerEvents();
        }

        var startProperties;
        this.transform = function(toX, toY, toScale, duration) {
            duration = duration || 0;
            
            toScale = parseFloat(toScale);
            toScale = toScale > viewBox.maxScale ? viewBox.maxScale : ( toScale < viewBox.minScale ? viewBox.minScale : toScale ) ;

            graphicSize = new GraphicSize(toScale);

            toX = graphicSize.fitX(toX);
            toY = graphicSize.fitY(toY);

            if(isNaN(toX + toY + toScale)) {
                return;
            }

            var startProperties = {
                    x : x,
                    y : y,
                    scale : scale		
            }

            $(startProperties).stop();

            $(startProperties).animate(
                    {
                        x : toX,
                        y : toY,
                        scale : toScale		
                    },
                    {
                        progress: svgTransform,
                        duration: duration,
                        easing: 'linear'
                    }
            );
        }

        this.moveToShape = function(shape, smooth) {
            if($(shape).length == 0)
                return;

            var scale = Math.max(1.5, viewPort.getScale());
            var graphicSize = new GraphicSize(scale);
            var translate = shape.get(0).transform.getTranslate();
            var bBox = shape.get(0).getBBox();

            var targetX = svgDimensions.originWidth() / 2 - translate.x - bBox.width / 2 ;
            var targetY = (svgDimensions.originHeight() / 2) + translate.y - bBox.height / 2;

            viewPort.transform(targetX,-targetY, scale, smooth ? animationDuration : 0)
        }

        this.getGraphicSize = function() {
            return graphicSize;
        }

        var marker = $('#Markers', svgElement);

        var triggerEvents = function() {
            for(var i = 0; i < events.length; i++) {
                events[i]();
            }	
            marker.hide();
            marker.show();
        }

        this.addUpdateEventListener = function(event) {
            events.push(event);
        }

        viewBox.addUpdateEventListener(function() {
            scale = (isFullSize || scale < viewBox.minScale) ? viewBox.minScale : scale ;
            $this.transform($this.getX(), $this.getY(), scale);
        });

        graphicSize = new GraphicSize(viewBox.minScale);
    }

    var ZoomEvent = function() {
        var $this = this;

        this.step = 0.5;

        this.x = 0;
        this.y = 0;

        this.startZoom = function(clientX, clientY) {
            $this.x = viewPort.getX() + clientX;
            $this.y = viewPort.getY() + clientY;
        }

        this.zoom = function(targetScale, animationDuration) {
            viewPort.transform($this.x, $this.y, targetScale, animationDuration);
        }

        this.stepZoom = function(delta) {
            $this.startZoom(0, 0);
            var targetScale = (viewPort.getScale() + this.step * delta) 
            viewPort.transform($this.x, $this.y, targetScale, animationDuration);
        }

        this.wheelZoom = function(event) {
            event.preventDefault();
            var currentScale = viewPort.getScale();
            var deltaY = (event.originalEvent.deltaY < 0 ? -1 : +1);

            var newScale = currentScale - $this.step * deltaY;

            if (newScale > viewBox.maxScale) {
                newScale = viewBox.maxScale;
            }
            else if(newScale < viewBox.minScale) {
                newScale = viewBox.minScale;
            }

            $this.zoomToPosition(event.clientX, event.clientY, newScale, event.currentTarget);
        }
        
        this.zoomToPosition = function(clientX, clientY, newScale, currentTarget) {
            var currentScale = viewPort.getScale();
            var ratio = 1 - currentScale / newScale;
            
            var pt = toSvgPosition(currentTarget, clientX, clientY);

            $this.x = viewPort.getX() - ( pt.x * ratio);
            $this.y = viewPort.getY() - ( pt.y * ratio);

            viewPort.transform($this.x, $this.y, newScale, animationDuration);
        }
    }

    var PanEvent = function() {

        var startX;
        var startY;

        var startClientX;
        var startClientY;

        var stopPan = function(event) {
            $('body').enableSelection();

            $(window).off('mousemove  touchmove', pan);
            $(window).off('mouseup  touchend', stopPan);

            $(svgContainer).css('cursor', '');
            $('a', svgElement).css('cursor', '');

        }	

        var pan = function(event) {

            $(svgContainer).css('cursor', 'move');
            $('a', svgElement).css('cursor', 'move');

            var x = ((event.clientX || event.touches[0].clientX) - startClientX ) / viewPort.getScale() + startX;
            var y = ((event.clientY || event.touches[0].clientY) - startClientY ) / viewPort.getScale() + startY;
            viewPort.move(x, y);

        }

        var startPan = function(event) {
            startX = viewPort.getX();
            startY = viewPort.getY();
            startClientX = (event.clientX || event.touches[0].clientX);
            startClientY = (event.clientY || event.touches[0].clientY);

            $('body').disableSelection();

            $(window).on('mousemove  touchmove', pan);
            $(window).on('mouseup  touchend', stopPan);
        }
        
        return {
            bind : function() {
                svgContainer.on('mousedown.pan  touchstart.pan', startPan);
            },
            unbind: function() {
                svgContainer.off('mousedown.pan  touchstart.pan');
                stopPan();
            }
        }
    }

    var Slider = function () {
        var $this = this;
        
        var slider = $('#zoom-leveler');
        var zoomEvent = new ZoomEvent();
        var precision = 10;
        

        var min = parseFloat(viewBox.minScale).toPrecision(precision);
        slider.attr('min', min);
        slider.val(min);
        
        slider.attr('max', 3);
        
        slider.on('mousedown touchstart', function() {
            zoomEvent.startZoom(0, 0);
        })

        slider.on('input change', function() {
            zoomEvent.zoom(slider.val());
        });
        
        viewBox.addUpdateEventListener(function() {
            slider.attr('min', parseFloat(viewBox.minScale).toPrecision(precision));
            slider.val(viewPort.getScale());
        });

        viewPort.addUpdateEventListener(function() {
            slider.val(viewPort.getScale());
        });
    }	

    var PanAndZoom = function() {
        var panEvent = new PanEvent();
        panEvent.bind();
        
        viewPort = new ViewPort();
        slider = new Slider();

        var zoomEvent = new ZoomEvent();
        svgContainer.on('wheel', zoomEvent.wheelZoom);

        this.appendSliderTo = function(container) {
            $(container).append(slider.sliderContainer);
        }
        
        $('#zoom-in').click(function() {
            zoomEvent.stepZoom(1);
        });
        
        $('#zoom-out').click(function() {
            zoomEvent.stepZoom(-1);
        });
        
        new PinchZoom(panEvent, zoomEvent);
        
        new Draggable(panAndZoomContainer, {container: '#process-graphic'});
        
        (function bindContainerEvents() {
            _graphic.panAndZoomSvgContainer.addClass('opacity-none');
            panAndZoomContainer.on('syc.visibility.shown', function() {
                _graphic.panAndZoomSvgContainer.removeClass('opacity-none');
            })
            panAndZoomContainer.on('syc.visibility.hide', function() {
                _graphic.panAndZoomSvgContainer.addClass('opacity-none');
                
                panAndZoomContainer.css('right', panAndZoomContainer.css('right'));
                panAndZoomContainer.css('bottom', panAndZoomContainer.css('bottom'));
                
                setTimeout(function() { //workaround damit die animation nach unten rechts auch gleichmäßig erfolgt
                    panAndZoomContainer
                        .snapRight()
                        .snapBottom();
                }, 10);
            })
        })();
    }

    function PinchZoom(panEvent, zoomEvent) {
        var startScale;
        
        interact(svgContainer[0]).gesturable({
            onstart: function(e) {
                isGesticulating = true;
                
                panEvent.unbind();
                
                startScale = viewPort.getScale();

                zoomEvent.startZoom(0, 0);
            },
            onmove: function(e) {
                var newScale = e.scale * startScale;
                zoomEvent.zoom(newScale);
            },
            onend: function(e) {
                setTimeout(function() {
                    isGesticulating = false;
                }, 100);
                
                panEvent.bind();
            },
        })
        .on('doubletap', function(e) {
            if(viewPort.getScale() >= 1.5) {   
                zoomEvent.zoom(viewBox.minScale, animationDuration);
            }
            else {
                var newScale = Math.max(1.5, viewPort.getScale());
                zoomEvent.zoomToPosition(e.clientX, e.clientY, newScale, e.currentTarget);
            }
        })
    } 
    
    this.loadGraphic = function() {
        svgContainer = _graphic.processContainer;
        svgContainer.loadingSpinner();
        
        return $.ajax({
            url: svgPath,
            dataType: "html"
        })
        .done(function(svg) {
            svgContainer.html(svg);
        })
        .done(_graphic.initSVG);

    }

    this.loadZoomWidgets = function() {
        this.panAndZoom = new PanAndZoom();
    }

    this.loadPanAndZoom = function() {
        panAndZoomContainer.show();
        
        $(this.panAndZoomSvgContainer).svg({
            settings: {
                viewBox: '0 0 ' + svgDimensions.originWidth()  + ' ' + svgDimensions.originHeight(),
                width: '100%',
                height: '100%'
            }
        });	
        var svg = $(this.panAndZoomSvgContainer).svg('get');

        svg.image(0, 0, '100%', '100%', svgPath);

        var g = svg.group();

        var rect = svg.rect(g, 0, 0, '100%', '100%', 0, 0, {
            'cursor': 'move',
            id: 'previewWindowRect'
        } 
        );

        var lineN = svg.line(g, 0, 0, 0, 0, {
//              cursor: 'n-resize'
        });
        var lineE = svg.line(g, 0, 0, 0, 0, {
//              cursor: 'e-resize'
        });
        var lineS = svg.line(g, 0, 0, 0, 0, {
//              cursor: 's-resize'
        });
        var lineW = svg.line(g, 0, 0, 0, 0, {
//              cursor: 'w-resize'
        });

        $(svg.root()).append(g);

        var pt = svg.root().createSVGPoint();

        var centerX = svgDimensions.originWidth() / 2;
        var centerY = svgDimensions.originHeight() / 2;

        var svgClientCoords = function(event) {
            pt.x = (event.clientX || event. touches[0].clientX);
            pt.y = (event.clientY || event. touches[0].clientY)
            pt = pt.matrixTransform(svg.root().getScreenCTM().inverse());
            return pt;
        }
        
        $(rect).on('mousedown  touchstart', function(event) {
            $('body').disableSelection();
            var selector = window;

            pt = svgClientCoords(event);
            diffX= pt.x - this.width.baseVal.value  / 2 - this.x.baseVal.value;
            diffY = pt.y - this.height.baseVal.value  / 2 - this.y.baseVal.value;

            $(selector).on('mousemove.pan  touchmove.pan', function(event) {
                pt = svgClientCoords(event);

                pt.x = centerX - pt.x + diffX;
                pt.y = centerY - pt.y + diffY;

                viewPort.move(pt.x, pt.y);

            });
            $(selector).on('mouseup.pan  touchend.pan', function() {
                $('body').enableSelection();
                $(selector).off('mousemove.pan  touchmove.pan');
                $(selector).off('mouseup.pan  touchend.pan');
            });
        });

        var strokeWidth;
        var strokeMargin;
        var updateStroke = function() {
            var strokeMultiplier = 2
            var width = svgDimensions.width() / $(_graphic.panAndZoomSvgContainer).width();
            var height = svgDimensions.height() / $(_graphic.panAndZoomSvgContainer).height()

            strokeWidth = 3 * Math.max(width, height);
            var resizeStrokeWidth = strokeWidth * strokeMultiplier
            strokeMargin = resizeStrokeWidth / 2 - strokeWidth / 2 

            var attributes = {
                    'stroke-width': strokeWidth
            };

            svg.change(g, attributes);
            svg.change(rect, attributes);
        }

        updateStroke();

        panAndZoomContainer.on('syc.visibility.shown', updateStroke);
        
        var updateStrokePosition = function() {
            var x1 = rect.x.animVal.value - strokeMargin;
            var x2 = rect.x.animVal.value + rect.width.animVal.value + strokeMargin;
            var y1 = rect.y.animVal.value - strokeMargin;
            var y2 = rect.y.animVal.value + rect.height.animVal.value + strokeMargin;

            svg.change(lineN, { x1: x1, x2: x2, y1: y1, y2: y1 });
            svg.change(lineE, { x1: x2, x2: x2, y1: y1, y2: y2 });
            svg.change(lineS, { x1: x1, x2: x2, y1: y2, y2: y2 });
            svg.change(lineW, { x1: x1, x2: x1, y1: y1, y2: y2 });
        }

        viewPort.addUpdateEventListener(function() {
            var translate = svgMainGroup.get(0).transform.getTranslate();
            svg.change(rect, {
                x: Math.abs(Math.min(0, translate.x)) - strokeWidth / 2,
                y: Math.abs(Math.min(0, translate.y)) - strokeWidth / 2,
                width: Math.min(svgDimensions.originWidth(), svgDimensions.originWidth() * viewBox.width / viewPort.getGraphicSize().width) + strokeWidth,
                height: Math.min(svgDimensions.originHeight(), svgDimensions.originHeight() * viewBox.height / viewPort.getGraphicSize().height) + strokeWidth
            });
            updateStrokePosition();
        });
    }

    this.updateView = function() {
        var width = svgContainer.width();
        var height = svgContainer.height();

        viewBox.update();

        this.panAndZoom.update();

    }

    this.findShape = function(nodeId) {
        var shape;

        for(var i = 0; i < typeMapping.FKT.id_field.length; i++) {
            var idField = typeMapping.FKT.id_field[i];

            var selector = 'v\\:cp[v\\:nameU="' + idField + '"], v\\:cp[v\\:nameu="' + idField + '"]';

            $(typeMapping.FKT.typeNames).each(function(key, typeName) {
                if(processElements[typeName] === undefined) {
                    return true;
                }

                $.each(processElements[typeName], function(key, object) {
                    if(object.nodeId == nodeId) {	
                        $(selector).each(function(index, props) {
                            if($(this).attr('v:val').indexOf('(' + object.shapeId + ')') != -1) {
                                shape = $(props).parents('g:first');
                                return false; 
                            }
                        });
                    }
                });

                if(shape !== undefined) {
                    return false;
                }
            });

            if(shape !== undefined) {
                break;
            }
        }
        if($(shape).parent().is('svg') == false) {
            return shape;	
        }
    }

    var currentlyHighlighted;

    this.unhighlightShape = function() {
        if(currentlyHighlighted && currentlyHighlighted.length > 0) {
            clearInterval(currentlyHighlighted.data('blink'));
            currentlyHighlighted.removeData('blink');
            currentlyHighlighted.attr('class', currentlyHighlighted.attr('class').replace('highlight', ''));
        }
    };

    this.highlightShape = function(shape) {
        var drawing = $(shape).children('g:first').children('rect, path, ellipse');

        var count = 3;
        var interval = setInterval(function() {
            var currentClass = $(drawing).attr('class') || '';

            if(currentClass.match('highlight')) {
                currentClass = currentClass.replace('highlight', '')
            } else {
                currentClass += " highlight";
            }
            if(count++ > 6) {
                clearInterval(interval);
            }

            $(drawing).attr('class', currentClass);
        }, 500);
        drawing.data('blink', interval);
        currentlyHighlighted = drawing;
    }

    this.focusShape = function(shape, smooth) {
        this.unhighlightShape();
        if(typeof shape === 'string') {
            shape = this.findShape(shape);
        }
        
        if(shape !== undefined) {
            selectedElementId = shape;
            viewPort.moveToShape(shape, smooth);
            this.highlightShape(shape);
        } 
    }
    
    var typeMapping = {
            FKT: {
                typeNames: ['function', 'workstep', 'process', 'qualitygate'],
                id_field: ['PK_ID', 'Fkt_ID']
            },
            DOK: {
                typeNames: ['information', 'document'],
                id_field: ['D_ID']

            },
            FB: {
                typeNames: ['division', 'employee', 'group'], 
                id_field: ['MAFB_ID']
            },
            RES: {
                typeNames: ['resource'],
                id_field: ['Res_ID']
            },
            KON: {
                typeNames: ['connector'],
                id_field: ['VID']
            },
            EVENTS: {
                'qualitygate': {
                    click: redirectToQGorOpenTargetList
                },
                'connector': {
                    onEventOverride : function(event) {
                        var thisConnector = $(this).data('processElement');
                        var count = 0;
                        var matchedConnector;

                        $.each(processElements.connector, function(key, connector) {
                            if (connector.nodeId == thisConnector.nodeId && connector.shapeId != thisConnector.shapeId) {
                                matchedConnector = connector;

                                if(++count > 1) {
                                    return false;
                                }
                            }
                        })

                        switch (count) {
                            case 2:
                                $(this).on('click', redirectToConnectorOrOpenTargetList);
                            case 1:
                                $(this).attr('href', matchedConnector.targetLink);
                                break;
                            case 0:
                                $(this).parent().append($(this).children());
                                $(this).remove();
                                break;
                        }
                    }
                }
            }
    };

    this.overrideAEvents = function() {
        var preventClick = false;
        $(svgElement)
        .on('mousedown  touchstart', 'a', function(event) {
            var $this = this;

            event.preventDefault();
            var startX = (event.clientX || event. touches[0].clientX);
            var startY = (event.clientY || event. touches[0].clientY);
            $($this).on('mousemove.pan  touchmove.pan', function(event) {
                var maxMove = 5;
                var moveX = Math.abs(startX - (event.clientX || event. touches[0].clientX));
                var moveY = Math.abs(startY - (event.clientY || event. touches[0].clientY));

                if(Math.max(moveX, moveY) > maxMove) {
                    preventClick = true;
                    $($this).off('mousemove.pan  touchmove.pan');
                }
            });

            $(window).one('mouseup.pan  touchend.pan', function() {
                $($this).off('mousemove.pan  touchmove.pan');
            });
        })
        .on('mouseup', 'a', function(event) {
            if (preventClick) {
                event.preventDefault();
            }

            preventClick = false;
        })
        .on('touchend', 'a', function(event) {
            if(isGesticulating) {
                return;
            }
            
            if (!preventClick) {
                var delegateClickEvent = $.Event('click');
                $(this).trigger(delegateClickEvent);
                
                if(!delegateClickEvent.isDefaultPrevented()) {
                    linkOpener.open($(this).attr('href'));
                }
            }

            preventClick = false;
        });    
    }

    this.overrideShapeAttributes = function() {
        $('title', svgElement).remove();

        var svgCreator = $($(document.createElement('div')).svg()).svg('get');

        this.overrideAEvents();

        $('a:has(g > desc)', svgElement).each(function(index, a) {
            var processElement = processElements.process[processObjectId];
            if(processElement !== undefined) {
                $(a).data('processElement', processElement);
                $(a).attr('href', processElement.link);
            }
            $(a).children('g').children('rect').remove();
        });

        $('g > v\\:custprops', svgElement).each(function(index, props) {
            var g = $(props).parent('g');
            var a = $(g).parent('a');

            var shapeType = vPropValue(props, 'nameU', 'Code');

            var link = undefined;

            if(shapeType !== undefined && typeMapping[shapeType] !== undefined) {
                var lblSelector = visioAttrSelector('lbl', '');
                var textSelector = visioAttrSelector('nameU', 'Text');

                var selector =  createBrowserIndipendentSelector('v:cp[v:lbl=""][v:nameU="Text"]');

                $(typeMapping[shapeType].typeNames).each(function(index, typeName) {
                    if(processElements[typeName] === undefined) {
                        return true; // continue
                    }

                    if(typeName == 'information' && !showOtherInformation) {
                        var docTypId = vPropValue(props, 'nameU' ,'Art');
                        if (docTypId == 1) {
                            return true;
                        }
                    }

                    var type = typeMapping[shapeType];


                    for(var i = 0; i < type.id_field.length; i++) {
                        var id = vPropValue(props, 'nameU' , type.id_field[i]);
                        if(id === undefined) {
                            continue;
                        }

                        var processElement = processElements[typeName][id];
                        if(processElement !== undefined) {
                            $(a).data('processElement', processElement);
                            link = processElement.link;

                            var events = typeMapping.EVENTS[typeName];
                            if(events != undefined) {
                                $.each(events, function(type, handler) {
                                    $(a).on(type, handler);
                                })
                            }

                            var title = processElement.comment || '';
                            
                            const maxLength = 500;
                            if(title.length > maxLength) {
                                title = title.substring(0, maxLength - 3) + '...';
                            }
                            $(g).attr('title', title);

                            return false; //break
                        }
                    }
                });
            }

            if(link !== undefined ) {
                $(a).attr('href', link);
                $(a).removeAttr('xlink:title');
            }
            else {
                $(a).replaceWith(g);
            }

            $(a).trigger('onEventOverride');
        });
    }
    
    function redirectToQGorOpenTargetList(event) {
        var element = $(event.currentTarget).data('processElement');
        
        var url = URITemplate(contextPath + '/api/{culture}/qualitygates/{id}/')
            .expand({
                culture: currentCulture,
                id: element.nodeId
            })
            
        redirectToSingleTargetorOpenTargetList(event, url);
    }
    
    function redirectToConnectorOrOpenTargetList(event) {
        var element = $(event.currentTarget).data('processElement');
        
        var url = URITemplate(contextPath + '/api/{culture}/connectors/{id}/')
            .expand({
                culture: currentCulture,
                id: element.shapeId
            })
            
        redirectToSingleTargetorOpenTargetList(event, url);
    }
    
    function redirectToSingleTargetorOpenTargetList(event, url) {
        const DISABLE_FLAG = 'loadingOrPopoverOpenConst';
        var shape = $(event.currentTarget);
        
        event.preventDefault();
        
        if(shape.data(DISABLE_FLAG)) {
            return;
        }
        
        shape.data(DISABLE_FLAG, true);
        
        var element = shape.data('processElement');
        _graphic.focusShape(element.nodeId);
        
        $.ajax({
            url: url
        })
        .fail(function() {
            shape.data(DISABLE_FLAG, false);
        })
        .done(function(data) {
            var a = $(data).find('a');
            
            if(a.length == 1) {
                shape.data(DISABLE_FLAG, false);
                linkOpener.open(a.attr('href'));

                return;
            }
            else {
                shape.popover({
                    content: data,
                    html: true,
                    placement: 'bottom',
                    trigger: 'manual'
                });
                
                shape.one('inserted.bs.popover', function(popoverEvent) {
                    var popoverContainer = shape.data('bs.popover').tip;
                    
                    $(popoverContainer).on('click', 'a', function() {
                        shape.popover('hide');
                    })
                    
                    $(document).on('mousedown.hide.on.focus.lost touchstart.hide.on.focus.lost', function(event) {
                        var clickedOutsideSelf = $(event.target).closest(shape[0]).length == 0;
                        var clickedOutsidePopover = $(event.target).closest(popoverContainer).length == 0;
                        
                        if(clickedOutsideSelf && clickedOutsidePopover) {
                            shape.popover('hide');
                        }
                    });
                });
                
                shape.one('hide.bs.popover', function() {
                    $(document).off('mousedown.hide.on.focus.lost touchstart.hide.on.focus.lost');
                })
                
                shape.one('hidden.bs.popover', function() {
                    shape.popover('dispose');
                    shape.data(DISABLE_FLAG, false);
                })
                
                shape.popover('show');
            }
        });
    }
    
    SVGAnimatedTransformList.prototype.findMatrixForType = function(type) {
        for (var i = 0; i < this.baseVal.numberOfItems; i++) {
            var item = this.baseVal.getItem(i);
            if(item.type === type) {
                return item.matrix;
            }
        }
    }

    SVGAnimatedTransformList.prototype.getTranslate = function() {
        var item = this.findMatrixForType(SVGTransform.SVG_TRANSFORM_TRANSLATE);
        return {
            x: item ? item.e : 0,
                    y: item ? item.f : 0
        }; 
    }

    SVGAnimatedTransformList.prototype.getScale = function() {
        return this.findMatrixForType(SVGTransform.SVG_TRANSFORM_SCALE).a; 
    }

    var vPropRegex = /VT[0-9]+\(((.|\s)*)\)/;

    var vColonRegex = /v:/g;
    var vAttributeRegex = /(\[v:)(.*?=)(.*?\])/g;

    var visioAttrSelector = function(attr, value) {
        return 'v:cp[v:'+ attr + '="' + value + '"]';
    }

    var createBrowserIndipendentSelector = function(selector) {

        while((match = vAttributeRegex.exec(selector)) !== null) {
            var findPart = match[0];
            var replacePart = match[1] + match[2].toLowerCase() + match[3];
            var lowerCaseSelector = selector.replace(findPart, replacePart);
        }
        var newSelector = selector;

        if(lowerCaseSelector !== undefined) {
            newSelector += "," + lowerCaseSelector;
        }

        return newSelector.replace(vColonRegex, "v\\:");
    }

    var vPropValue = function(props, attr, fieldName) {
        var selector = visioAttrSelector(attr, fieldName);

        selector = createBrowserIndipendentSelector(selector);

        return vPropValueForSelector(props, selector);
    }

    var vPropValueForSelector = function(props, selector) {
        var value = $(props).children(selector).not('[v\\:val*="(0)"]').attr('v:val');

        var match = vPropRegex.exec(value);
        if(match != null) {
            return match[1];
        }
    }

    var Bzzzz = function() {

        $('#panandzoom').dblclick(function() {
            $('#panandzoom').off('dblclick');
            var word = '';
            $(document).on('keypress.bzzzz', function(event) {
                word += String.fromCharCode(event.charCode || event.keyCode);

                if(word.match(/party$/)) {
                    $(document).off('keypress.bzzzz');
                    alert("Party Mode!");
                    $('g:has(v\\:custprops)', svgElement).off('click');
                    $('g:has(v\\:custprops)', svgElement).click(function(event) {
                        var $this = this;
                        event.preventDefault();
                        event.stopPropagation();

                        var translate = event.currentTarget.transform.getTranslate();

                        var box = $(this).find('path, rect').siblings('v\\:textrect');
                        var cx = box.attr('cx')
                        var cy = box.attr('cy');
                        var translateParam = ' translate(' + translate.x + ', ' + translate.y + ') ';

                        $({rotate: 0}).animate({
                            rotate: 3600
                        }, {
                            duration: 10000
                            , easing: 'easeInOutElastic'
                                ,progress: function(animation, progress) {
                                    var transformRotate = ' rotate(' + this.rotate + ' ' + cx + ' ' + cy + ' )';
                                    var transform = translateParam + transformRotate;

                                    $($this).attr('transform', transform);
                                }
                        })
                    })
                }
                word = word.substr(-20);

            })
        })
    }
    
    
    function toSvgPosition(target, x, y) {
        var pt = svgElement.createSVGPoint();
        var offset = $(target).parent().offset();

        pt.x = (x - offset.left) ;
        pt.y = (y - offset.top) ;
        pt = pt.matrixTransform(svgMainGroup[0].getCTM().inverse());

        pt.x = pt.x - svgDimensions.originWidth() / 2 + viewPort.getX();
        pt.y = pt.y - svgDimensions.originHeight() / 2 + viewPort.getY();
        
        return pt;
    }
}



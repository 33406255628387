$(function () {
  if (useKeycloak) {
    initKeycloak();
  }

  var keycloak;

  function initKeycloak() {
    $.getJSON('/keycloak/keycloak.json').done(info => {
      keycloak = new Keycloak(info.config);

      let initSettings = {
        enableLogging: false,
        checkLoginIframe: false,
        ...info.context
      }

      keycloak.init(initSettings)
        .then(() => addBearerTokenToAllAjaxRequests())
        .catch(e => {
          console.warn(e);
          alert('failed to authenticate: please reload page or relogin again');
        });
    });
  }

  function addBearerTokenToAllAjaxRequests() {
    $(document).ajaxSend(function (_event, _jqxhr, settings) {
      if (keycloak && keycloak.token) {
        var origXhrFunc = settings.xhr;
        
        settings.xhr = function () {
          var xhr = origXhrFunc();
          var origSend = xhr.send;
          
          xhr.send = function () {
            var origArguments = arguments;
            
            keycloak.updateToken(150)
              .then(() => {
                const token = 'Bearer ' + keycloak.token
                xhr.setRequestHeader('Authorization', token);
                
                origSend.apply(xhr, origArguments);
              })
              .catch(() => {
                console.error('failed to update token; reload the page');
                window.location.reload();
              });
          };

          return xhr;
        };
      }
    });
  }
});